import { defineStore } from 'pinia';
import type Stripe from 'stripe';
import { groupedFunctions } from 'src/fb';
import { Notify } from 'quasar';

export enum SubscriptionLevel {
  Standard = 'Standard',
  Plus = 'Plus',
  Pro = 'Pro',
  Promax = 'Pro Max',
  Ultra = 'Ultra',
}

export const PRICES_SUBSCRIPTION = {
  // [monthly price id, yearly/quarterly price id]
  [SubscriptionLevel.Standard]: ['', ''],
  [SubscriptionLevel.Plus]: [
    'price_1Mg4yDEoTOtb5eZvNTBFQCpe',
    'price_1N4PgqEoTOtb5eZvGOb1laYH',
  ],
  [SubscriptionLevel.Pro]: [
    'price_1Mg50kEoTOtb5eZvjJYr5NSn',
    'price_1N4PinEoTOtb5eZvGb6bwmGq',
  ],
  [SubscriptionLevel.Promax]: [
    'price_1Mg53CEoTOtb5eZva8LNKLx0',
    'price_1N4PkAEoTOtb5eZv0GC279dX',
  ],
  [SubscriptionLevel.Ultra]: [
    'price_1MskG2EoTOtb5eZv0iiClC6z',
    'price_1NBED0EoTOtb5eZvXuKG8KxF',
  ],
} as Record<SubscriptionLevel, [string, string]>;

export const SUBSCRIPTION_DETAILS: Record<string, {monthly_credits: string; credit_price: string; quota_ratio: string}> = {
  [SubscriptionLevel.Plus]: {
    monthly_credits: '400',
    credit_price: '~$0.025',
    quota_ratio: '1:10',
  },
  [SubscriptionLevel.Pro]: {
    monthly_credits: '2000',
    credit_price: '~$0.020',
    quota_ratio: '1:20',
  },
  [SubscriptionLevel.Promax]: {
    monthly_credits: '10000',
    credit_price: '~$0.010',
    quota_ratio: '1:30',
  },
  [SubscriptionLevel.Ultra]: {
    monthly_credits: '',
    credit_price: '~$0.0010',
    quota_ratio: '1:50',
  },
}

interface Period {
  start: number;
  end: number;
}

interface Subscription {
  interval: Stripe.Subscription.PendingInvoiceItemInterval.Interval;
  period: Period;
}

export interface State {
  subscriptions: Partial<Record<SubscriptionLevel, Subscription>>;
}

export const useLegacySubscriptionStore = defineStore('legacy-subscription', {
  state: (): State => ({
    subscriptions: {},
  }),
  getters: {
    isSubscriber: (state) => {
      return Object.keys(state.subscriptions).length > 0;
    },
    topSubscription: (state) => {
      const ladder = [
        SubscriptionLevel.Standard,
        SubscriptionLevel.Plus,
        SubscriptionLevel.Pro,
        SubscriptionLevel.Promax,
        SubscriptionLevel.Ultra,
      ];

      for (const level of ladder.reverse()) {
        if (Object.keys(state.subscriptions).includes(level)) {
          return level;
        }
      }

      return SubscriptionLevel.Standard;
    },
  },
  actions: {
    findPlanByPrice(price: string): SubscriptionLevel | undefined {
      let result;

      Object.entries(PRICES_SUBSCRIPTION).forEach(([plan, prices]) => {
        if (prices.includes(price)) {
          result = plan as SubscriptionLevel;
        }
      });

      return result;
    },

    setSubscription(stripeInvoiceHistory: Stripe.Invoice[]) {
      const validLineItems: Stripe.InvoiceLineItem[] = [];

      stripeInvoiceHistory.forEach(
        ({ lines, status }) => {
          if (status === 'paid') {
            const { data: items } = lines;

            if (items.length > 0) {
              items.forEach((item) => {
                if (
                  item.price?.active
                  && item.price.id
                  && item.period.end > new Date().getTime() / 1000
                  && item.type === 'subscription'
                ) {
                  validLineItems.push(item);
                }
              })
            }
          }
        }
      );

      for (const validLineItem of validLineItems) {
        if (!validLineItem.price?.id || !validLineItem.plan?.interval) return;

        const plan = this.findPlanByPrice(validLineItem.price.id);

        if (plan === undefined) {
          continue;
        }

        this.subscriptions[plan] = {
          interval: validLineItem.plan.interval,
          period: {
            start: validLineItem.period.start,
            end: validLineItem.period.end,
          },
        };
      }
    },

    manageSubscription() {
      groupedFunctions
        .call<{ url: string }>('account', 'createStripeCustomerPortalSession', {
          configuration: 'bpc_1OvtROEoTOtb5eZvvDQzWOOX',
          origin: window.location.origin,
        })
        .then((result: any) => {
          console.log(result, 'result');
          window.location.href = result.data.url;
        })
        .catch((error: any) => {
          console.error(error);
          Notify.create({
            message: error.message,
            caption: this.t('error_with_payment'),
            color: 'negative',
            icon: 'error',
          });
        })
        .finally(() => {
          console.log('buyCredits finally');
        });
    }
  },
});
