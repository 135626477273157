import { defineStore } from 'pinia';
import type Stripe from 'stripe';

import type { TrialItem } from './user-store';

export enum SubscriptionLevel {
  Standard = 'Standard',
  Pro = 'Pro',
  Promax = 'Pro Max',
  Ultra = 'Ultra',
}

export const PRICES_SUBSCRIPTION = {
  // [monthly price id, yearly/quarterly price id]
  [SubscriptionLevel.Standard]: ['', ''],
  [SubscriptionLevel.Pro]: [
    'price_1OlkpLEoTOtb5eZvfb8YdEwf',
    'price_1OlkpLEoTOtb5eZvikv8BojG',
  ],
  [SubscriptionLevel.Promax]: [
    'price_1OlktEEoTOtb5eZvvDrzIwI6',
    'price_1OlktEEoTOtb5eZviuJfhQ0R',
  ],
  [SubscriptionLevel.Ultra]: ['', ''],
} as Record<SubscriptionLevel, [string, string]>;

interface Period {
  start: number;
  end: number;
}

interface Subscription {
  id: string;
  interval: Stripe.Subscription.PendingInvoiceItemInterval.Interval;
  period: Period;
  status: Stripe.Subscription.Status;
  cancelAtPeriodEnd: boolean;
}

export interface State {
  subscriptions: Partial<Record<SubscriptionLevel.Pro | SubscriptionLevel.Promax, Subscription>>;
  trials: Record<string, TrialItem>;
}

export const useSubscriptionStore = defineStore('subscription', {
  state: (): State => ({
    subscriptions: {},
    trials: {},
  }),
  getters: {
    activeSubscriptions(state) {
      return Object.keys(state.subscriptions) as (SubscriptionLevel.Pro | SubscriptionLevel.Promax)[];
    },
    isSubscriber(): boolean {
      return Object.keys(this.subscriptions).length > 0;
    },
    topSubscription(state) {
      const ladder = [
        SubscriptionLevel.Standard,
        SubscriptionLevel.Pro,
        SubscriptionLevel.Promax,
        SubscriptionLevel.Ultra,
      ];

      for (const level of ladder.reverse()) {
        if (Object.keys(state.subscriptions).includes(level)) {
          return level;
        }
      }

      return SubscriptionLevel.Standard;
    },

    topSubscriptionId(): string | undefined {
      if (this.topSubscription === SubscriptionLevel.Standard || this.topSubscription === SubscriptionLevel.Ultra) {
        return undefined;
      }

      return this.subscriptions[this.topSubscription]?.id;
    },

    /**
     * Whether this user has used trail chance (one account only has one trial chance)
     */
    usedTrial(state) {
      return state.trials && Object.keys(state.trials).length > 0;
    }
  },
  actions: {
    findPlanByPrice(price: string): SubscriptionLevel.Pro | SubscriptionLevel.Promax | undefined {
      let result;

      Object.entries(PRICES_SUBSCRIPTION).forEach(([plan, prices]) => {
        if (prices.includes(price)) {
          result = plan as SubscriptionLevel.Pro | SubscriptionLevel.Promax;
        }
      });

      return result;
    },

    setSubscription({
      stripeSubscriptions: subscriptions,
      trialHistory
    } : {
      stripeSubscriptions: Stripe.Subscription[],
      trialHistory?: Record<string, TrialItem>
    }) {
      this.trials = trialHistory ?? {};

      subscriptions.forEach((s) => {
        s.items?.data?.forEach((item) => {
          const plan = this.findPlanByPrice(item.price.id);

          if (plan === undefined) {
            return;
          }

          this.subscriptions[plan] = {
            id: s.id,
            interval: item.plan.interval,
            period: {
              start: s.current_period_start,
              end: s.current_period_end,
            },
            status: s.status,
            cancelAtPeriodEnd: s.cancel_at_period_end,
          };
        })
      })
    },
  },
});
