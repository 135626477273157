import enUS from './en-US/index.json';
import de from './de/index.json';
import es from './es/index.json';
import fr from './fr/index.json';
import it from './it/index.json';
import ja from './ja/index.json';
import ko from './ko/index.json';
import ru from './ru/index.json';
import zhCN from './zh-CN/index.json';
import zhTW from './zh-TW/index.json';
import mn from './mn/index.json';

type I18nMessage = typeof enUS;

interface I18nList {
  'en-US': I18nMessage;
  de: I18nMessage;
  fr: I18nMessage;
  es: I18nMessage;
  it: I18nMessage;
  'zh-CN': I18nMessage;
  ru: I18nMessage;
  ja: I18nMessage;
  'zh-TW': I18nMessage;
  ko: I18nMessage;
  mn: I18nMessage;
}

export const defaultLanguage = 'en-US';
export default {
  'en-US': enUS,
  de: de,
  fr: fr,
  es: es,
  it: it,
  'zh-CN': zhCN,
  ru: ru,
  ja: ja,
  'zh-TW': zhTW,
  ko: ko,
  mn: mn,
} as unknown as I18nList;
