<template>
  <router-view />
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import {
  getCssVar,
  setCssVar,
  useQuasar,
} from 'quasar';
import { storeToRefs } from 'pinia';

import { useUserStore } from 'stores/user-store';

const { setLocaleMessage, locale, messages } = useI18n({ useScope: 'global' });
const userStore = useUserStore();
const { settings } = storeToRefs(userStore);
const $q = useQuasar();

watch(
  () => userStore.language,
  async (language) => {
    const languagePromises = [];
    try {
      // update locale for dayjs
      const dayjsLangList = import.meta.glob(
        '../node_modules/dayjs/esm/locale/*.js'
      );
      const parsedLocale = language === 'en-US' ? 'en' : language;

      document.documentElement.lang = parsedLocale;
      const dayjsPromise = dayjsLangList[
        `../node_modules/dayjs/esm/locale/${parsedLocale.toLowerCase()}.js`
      ]().then((lang) => {
        dayjs.locale(lang.default);
      });
      languagePromises.push(dayjsPromise);

      // update messages against the locale for i18n
      const i18nLocale = language === 'en' ? 'en-US' : language;

      if(!Object.keys(messages.value).includes(i18nLocale)) {
        const messageList = import.meta.glob(
          './i18n/**/*.json'
        );
        const i18nPromise = messageList[
          `./i18n/${i18nLocale}/index.json`
        ]?.().then((message) => {
          setLocaleMessage(i18nLocale, message.default);
        });
        languagePromises.push(i18nPromise);
      }

      // update messages against the locale for quasar
      const langList = import.meta.glob('../node_modules/quasar/lang/*.mjs');
      const quasarLanguageMap = {
        'ko': 'ko-KR',
      }

      const quasarI18nPromise = langList[`../node_modules/quasar/lang/${quasarLanguageMap[language] ?? language}.mjs`]?.().then(lang => {
        $q.lang.set(lang.default)
      });
      languagePromises.push(quasarI18nPromise);

    } catch (err) {
      // Requested Quasar Language Pack does not exist,
      // let's not break the app, so catching error
      console.error(err);
    } finally {
      await Promise.allSettled(languagePromises);
      locale.value = language;
    }
  },
  { immediate: true }
);

watch(() => settings.value.darkMode, (val) => {
  if (val === null) {
    return;
  }

  $q.dark.set(val);
}, {immediate: true});

watch(() => $q.dark.isActive, (val) => {
  if (val) {
    setCssVar('primary', '#B894F6');
    const accent_color = getCssVar('accent');
    if (accent_color) {
      $q.addressbarColor.set(accent_color);
    }
  } else {
    setCssVar('primary', '#6200EE');
    const primary_color = getCssVar('primary');
    if (primary_color) {
      $q.addressbarColor.set(primary_color);
    }
  }
}, { immediate: true });
</script>
