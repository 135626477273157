import { groupedFunctions, db, auth, apiBaseUrl } from 'src/fb';
import { defineStore } from 'pinia';
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { ChatSession, Message, ChatType, SendingMessageOptions, InteractiveExample } from '../types/interactive';
import { useUserStore } from './user-store';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { ASSISTANT_EXAMPLES, OPTIMIZER_EXAMPLES } from '../constants/interactive-examples';


const OPTIMIZER_MESSAGES: Message[] = [{
  role: 'user',
  content: `
I need an image of a cute bear cub eating honey.
The image will be used as a cover for a children’s story book.
  `,
}, {
  role: 'assistant',
  content: `
Here is the optimized prompt according to your request:
<prompt>
Create an adorable and heartwarming image of a bear cub eating honey, perfect for the cover of a children’s story book.
The scene should be set in a lush, vibrant forest with dappled sunlight filtering through the trees, creating a warm and inviting atmosphere.
In the center of the image, a curious and playful bear cub sits on a fallen log, as it dips its paw into a golden jar of honey.
The honey jar should be a classic, clear glass jar with a red and white checkered lid, slightly tilted as the cub reaches inside.
</prompt>
<question>
Would you like the bear cub to be alone or with its family?
</question>
<question>
Is there a specific bear species you prefer?
<question>
  `,
}];

const ASSISTANT_MESSAGES: Message[] = [{
  role: 'user',
  content: `
Create an adorable and heartwarming image of a bear cub eating honey, perfect for the cover of a children’s story book.
The scene should be set in a lush, vibrant forest with dappled sunlight filtering through the trees, creating a warm and inviting atmosphere.
In the center of the image, a curious and playful bear cub sits on a fallen log, as it dips its paw into a golden jar of honey.
The honey jar should be a classic, clear glass jar with a red and white checkered lid, slightly tilted as the cub reaches inside.
  `,
}, {
  role: 'assistant',
  content: '<image>/interactive_result_1.png</image>',
}];

enum MessageEvent {
  TEXT = 'text',
  GOOGLE_SEARCH = 'googleSearch',
  URL_CRAWLING = 'urlCrawling',
  URL_META = 'urlMeta',
  ERROR = 'error',
  QUEUED_REQUEST = 'enqueuedMessage',
  IMAGE = 'image',
}

interface State {
  assistantMessagesCollection: { [key: string]: Message[]; };
  optimizerMessagesCollection: { [key: string]: Message[]; };
  history: ChatSession[];
  isLoadingHistory: boolean;
  isSendingAssistantMessage: boolean;
  isSendingOptimizerMessage: boolean;
  isInitializingChatSession: boolean;
  isLoadingChatSession: boolean;
  currentChatSessionId: string;
  abortControllers: Record<ChatType, any>;
  pollingControllers: Record<string, any>;
  splitterModel: number;
  showSidebar: boolean;
  autoScroll: Record<ChatType, boolean>;
  showedTutorial: Record<ChatType, boolean>;
  messageSent: Record<ChatType, boolean>;
  pagination: {
    pageIndex: number;
    pageSize: number;
    total: number;
  };
}

export const useInteractiveStore = defineStore('interactive', {
  state: (): State => ({
    assistantMessagesCollection: {},
    optimizerMessagesCollection: {},
    history: [],
    isLoadingHistory: false,
    isSendingAssistantMessage: false,
    isSendingOptimizerMessage: false,
    isInitializingChatSession: false,
    isLoadingChatSession: false,
    currentChatSessionId: '',
    abortControllers: {
      assistant: null,
      optimizer: null,
    },
    pollingControllers: {},
    splitterModel: 50,
    showSidebar: true,
    autoScroll: {
      assistant: true,
      optimizer: true,
    },
    showedTutorial: {
      [ChatType.ASSISTANT]: false,
      [ChatType.OPTIMIZER]: false,
    },
    messageSent: {
      [ChatType.ASSISTANT]: false,
      [ChatType.OPTIMIZER]: false,
    },
    pagination: {
      pageIndex: 1,
      pageSize: 20,
      total: 0,
    },

  }),

  getters: {
    assistantMessages: (state) => state.assistantMessagesCollection[state.currentChatSessionId] || [],
    optimizerMessages: (state) => state.optimizerMessagesCollection[state.currentChatSessionId] || [],
  },

  actions: {

    resetMessages() {
      this.currentChatSessionId = '';
      this.autoScroll.assistant = true;
      this.autoScroll.optimizer = true;
    },

    initialMessageForTutorial() {
      this.assistantMessagesCollection['tutorial'] = [];
      this.optimizerMessagesCollection['tutorial'] = [];
      this.currentChatSessionId = 'tutorial';
    },

    setMessageForTutorial(type: ChatType) {
      if (type === 'assistant') {
        this.assistantMessagesCollection['tutorial'].push(...ASSISTANT_MESSAGES);
      } else {
        this.optimizerMessagesCollection['tutorial'].push(...OPTIMIZER_MESSAGES);
      }
    },

    getExampleMessages(type: ChatType, expectedLength: number = 4) {
      const selectedExamples: InteractiveExample[] = [];

      const userStore = useUserStore();
      const allExamples = type === 'assistant' ? ASSISTANT_EXAMPLES : OPTIMIZER_EXAMPLES;
      const matchedExamples: InteractiveExample[] = [];
      const otherExamples: InteractiveExample[] = [];

      allExamples.forEach((example) => {
        if (example.category?.some((category) => userStore.settings.plan?.includes(category))) {
          matchedExamples.push(example);
        } else {
          otherExamples.push(example);
        }
      });

      while (selectedExamples.length < expectedLength) {
        if (matchedExamples.length === 0 && otherExamples.length === 0) {
          break;
        }

        if (matchedExamples.length > 0) {
          const index = Math.floor(Math.random() * matchedExamples.length);
          selectedExamples.push(matchedExamples[index]);
          matchedExamples.splice(index, 1);
        } else if (otherExamples.length > 0) {
          const index = Math.floor(Math.random() * otherExamples.length);
          selectedExamples.push(otherExamples[index]);
          otherExamples.splice(index, 1);
        }
      }

      return selectedExamples;
    },

    async initializeChatSession() {
      if (this.isInitializingChatSession) return;

      this.isInitializingChatSession = true;
      const userStore = useUserStore();

      try {
        const result = await groupedFunctions.request('interactiveOptimizer', 'initChatSession', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        }) as any;
        this.currentChatSessionId = result.data.chatSessionId;
        userStore.submitUserConversion();
      } catch (e: any) {
        this.handleError(e);
      } finally {
        this.listHistory();
        this.isInitializingChatSession = false;
      }
    },

    async sendMessage(message: string, target: ChatType, chatSessionId: string, images: string[] = [], imageModel?: string) {
      this.abortControllers[target] = new AbortController();

      return await groupedFunctions.request('interactiveOptimizer', 'chat', {
        body: JSON.stringify({
          message,
          images,
          target,
          chatSessionId,
          version: 'v3',
          imageModel
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortControllers[target].signal,
      }) as any;
    },

    async previewImage(prompt: string, model: string, target: ChatType, chatSessionId: string) {
      this.abortControllers[target] = new AbortController();

      return await groupedFunctions.request('interactiveOptimizer', 'previewImage', {
        body: JSON.stringify({
          prompt,
          model,
          target,
          chatSessionId,
          version: 'v3',
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortControllers[target].signal,
      }) as any;
    },

    async checkPendingTask(taskId: string, taskType: string) {
      return await groupedFunctions.request('interactiveOptimizer', 'checkPendingTask', {
        body: JSON.stringify({
          taskId,
          taskType
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      }) as any;
    },

    async cancelSend(target?: ChatType) {
      if (target) {
        this.abortControllers[target]?.abort?.();
        this.abortControllers[target] = null;
      } else {
        for (const key in this.abortControllers) {
          this.abortControllers[key as ChatType]?.abort?.();
          this.abortControllers[key as ChatType] = null;
        }
      }
    },

    async sendAssistantMessage(messageOptions: SendingMessageOptions, chatSessionId: string, image?: File) {
      const { prompt: message, model, modality, images, imageModel, action } = messageOptions;
      const chunks = [];
      if (message) {
        chunks.push(message);
      }
      if (image) {
        chunks.push(image);
      }
      if (!chunks.length) {
        return;
      }
      if (this.isSendingAssistantMessage) {
        return;
      }
      this.isSendingAssistantMessage = true;

      try {

        if (!this.assistantMessagesCollection[chatSessionId]) {
          this.assistantMessagesCollection[chatSessionId] = [];
        }

        const userMessage: Message = {
          role: 'user',
          content: chunks,
          images: images?.map(i => ({ url: i })),
        };
        this.assistantMessagesCollection[chatSessionId].push(userMessage);
        this.assistantMessagesCollection[chatSessionId].push({ role: 'assistant', content: [] });

        let stream: any;
        // check if user wants to preview image prompt or upscale image
        if (modality === 'image') {
          if (action === 'upscale_image') {
            if (!images) return;
            userMessage.reference = {
              type: 'image',
              value: [images[0]],
            };
            stream = await this.upscaleImage({
              chatSessionId,
              message: this.t('upscale_image.this_image'),
              image: images[0]
            });
          } else {
            stream = await this.previewImage(message, imageModel ?? model ?? 'dall-e-3', ChatType.ASSISTANT, chatSessionId);
          }
        } else {
          // let b64Images = [] as string[];
          // if (images) b64Images = await Promise.all(images.map(i => fileToBase64Image(i)));
          stream = await this.sendMessage(message, ChatType.ASSISTANT, chatSessionId, images, imageModel);
        }

        const rChunks: string[] = [];
        for await (const event of stream) {
          // console.log('Assistant: ', event);
          if (event.event === MessageEvent.TEXT) {
            rChunks.push(event.data || '');
            this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1].content = rChunks.join('');
          }

          if (event.event === MessageEvent.IMAGE) {
            const message = this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1];
            message.content = event.data || '';
            const idRegex = /messageId="(.*?)"/gs;
            const match = idRegex.exec(event.data);
            const messageId = match?.[1];
            if (messageId) {
              message.id = messageId;
            }
          }

          if (event.event === MessageEvent.URL_META) {
            try {

              const { url, title, desc, icon } = JSON.parse(event.data);
              const sources = this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1].sources ?? [];
              sources.push({
                url,
                title,
                desc,
                icon
              });
              this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1].sources = sources;
            } catch (e) {
              // do not break the loop
            }
          }

          if (event.event === MessageEvent.QUEUED_REQUEST) {
            try {
              const message = this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1];
              message.content = event.data || '';
              message.id = event.id;

              await this.pollPendingRequests(this.extractPendingRequest(event.data, chatSessionId, event.id));
            } catch (e) {
              // do not break the loop
            }
          }

          if (event.event === MessageEvent.ERROR) {
            throw new Error(event.data);
          }
        }
      } catch (e: any) {
        if (e.name === 'AbortError') {
          this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1].content += ' ';
          this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1].stopped = true;
        } else {
          if (this.assistantMessagesCollection[chatSessionId]) {
            const message = e.message;
            const failedPendingRequest = message.includes('<PendingRequest>');

            this.assistantMessagesCollection[chatSessionId][this.assistantMessagesCollection[chatSessionId].length - 1].content = failedPendingRequest ? message : this.t('interactive.send_failed');

            if (!failedPendingRequest) this.handleError(e);
          }
        }
      } finally {
        if (this.assistantMessagesCollection[chatSessionId]?.length === 2) {
          this.listHistory();
        }
        this.isSendingAssistantMessage = false;
      }
    },

    async sendOptimizerMessage(messageOptions: SendingMessageOptions, chatSessionId: string) {
      const { prompt: message, images } = messageOptions;
      const chunks = [];
      if (message) {
        chunks.push(message);
      }
      if (!chunks.length) {
        return;
      }
      if (this.isSendingOptimizerMessage) {
        return;
      }
      this.isSendingOptimizerMessage = true;

      try {

        if (!this.optimizerMessagesCollection[chatSessionId]) {
          this.optimizerMessagesCollection[chatSessionId] = [];
        }

        this.optimizerMessagesCollection[chatSessionId].push({
          role: 'user',
          content: chunks,
          images: images?.map(i => ({ url: i as any }))
        });
        this.optimizerMessagesCollection[chatSessionId].push({ role: 'assistant', content: [] });

        const stream = await this.sendMessage(message, ChatType.OPTIMIZER, chatSessionId, images);

        const rChunks: string[] = [];
        for await (const event of stream) {
          // console.log('Optimizer: ', event.data);
          if (event.event === 'text') {
            rChunks.push(event.data || '');
            this.optimizerMessagesCollection[chatSessionId][this.optimizerMessagesCollection[chatSessionId].length - 1].content = rChunks.join('');
          }

          if (event.event === MessageEvent.ERROR) {
            throw new Error(event.data);
          }
        }
      } catch (e: any) {
        if (e.name === 'AbortError') {
          this.optimizerMessagesCollection[chatSessionId][this.optimizerMessagesCollection[chatSessionId].length - 1].content += ' ';
          this.optimizerMessagesCollection[chatSessionId][this.optimizerMessagesCollection[chatSessionId].length - 1].stopped = true;
        } else {
          if (this.optimizerMessagesCollection[chatSessionId]) {
            this.optimizerMessagesCollection[chatSessionId][this.optimizerMessagesCollection[chatSessionId].length - 1].content = this.t('interactive.send_failed');
            this.handleError(e);
          }
        }
      } finally {
        if (this.optimizerMessagesCollection[chatSessionId]?.length === 2) {
          this.listHistory();
        }
        this.isSendingOptimizerMessage = false;
      }
    },

    async listHistory({ pageIndex, pageSize }: { pageIndex: number; pageSize: number; } = { pageIndex: 1, pageSize: 20 }) {
      if (!auth.currentUser) {
        return;
      }
      this.isLoadingHistory = true;

      try {
        const { data, meta } = await groupedFunctions.request('interactiveOptimizer', 'getchatSessions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            sort: '-createdAt',
            pageIndex: pageIndex,
            pageSize: pageSize,
          }),
        }) as any;
        const findInsertIndex = (item: ChatSession) => {
          let left = 0;
          let right = this.history.length - 1;
          while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const midDate = new Date(this.history[mid].createdAt).getTime();
            const itemDate = new Date(item.createdAt).getTime();
            const leftDate = new Date(this.history[left].createdAt).getTime();
            const rightDate = new Date(this.history[right].createdAt).getTime();
            if (itemDate > leftDate) {
              return left;
            } else if (itemDate < rightDate) {
              return right + 1;
            } else if (itemDate > midDate) {
              right = mid - 1;
            } else {
              left = mid + 1;
            }
          }
          return left;
        };
        data.forEach((item: ChatSession) => {
          const index = this.history.findIndex((i) => i.chatSessionId === item.chatSessionId);
          if (index > -1) {
            this.history.splice(index, 1, item);
          } else {
            this.history.splice(findInsertIndex(item), 0, item);
          }
        });
        this.pagination.total = meta.total;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoadingHistory = false;
      }
    },

    clearPolling() {
      Object.values(this.pollingControllers).forEach((timeout) => {
        clearTimeout(timeout);
      });
      this.pollingControllers = {};
    },

    extractPendingRequest(message: string, chatSessionId: string, messageId: string) {
      const idRegex = /<Id>(.*?)<\/Id>/gs;
      const statusRegex = /<Status>(.*?)<\/Status>/gs;
      const taskTypeRegex = /<TaskName>(.*?)<\/TaskName>/gs;
      const matchId = idRegex.exec(message);
      const matchStatus = statusRegex.exec(message);
      const taskId = matchId?.[1] ?? '';
      const status = matchStatus?.[1] ?? '';
      const matchTaskType = taskTypeRegex.exec(message);
      const taskType = matchTaskType?.[1] ?? '';
      return {
        taskId,
        status,
        taskType,
        chatSessionId,
        messageId,
      };
    },

    async viewHistoryItem(id: string) {
      this.cancelSend();
      this.isLoadingChatSession = true;
      this.assistantMessagesCollection[id] = [];
      this.optimizerMessagesCollection[id] = [];
      this.currentChatSessionId = id;

      this.clearPolling();

      try {
        const data = await this.getHistoryItem(id);

        this.assistantMessagesCollection[id] = data.assistant.map((item: any) => {
          if (item.message.includes('<PendingRequest')) {
            const options = this.extractPendingRequest(item.message, id, item._id);
            this.pollPendingRequests(options);
            if (options.status === 'stopped') item.stopped = true;
          }

          return {
            role: item.type,
            content: item.message,
            createdAt: item.createdAt,
            sources: item.sources,
            images: item.inputImages,
            reference: item.reference,
            id: item._id,
            stopped: item.stopped,
          };
        });
        this.optimizerMessagesCollection[id] = data.optimizer.map((item: any) => ({
          role: item.type,
          content: item.message,
          createdAt: item.createdAt,
          images: item.inputImages,
          reference: item.reference,
          id: item._id,
        }));
      } catch (error) {
        this.currentChatSessionId = '';
        this.handleError(error);
      } finally {
        this.isLoadingChatSession = false;
      }
    },

    async downloadHistoryItem(session: ChatSession) {
      try {
        const data = await this.getHistoryItem(session.chatSessionId);
        const blob = new Blob([JSON.stringify({
          assistant: data.assistant,
          optimizer: data.optimizer
        }, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `chat-session-${session.name || session.chatSessionId}.json`;
        a.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        this.handleError(error);
      }
    },

    async getHistoryItem(id: string) {
      const { data } = await groupedFunctions.request('interactiveOptimizer', 'getChatSessionHistory', {
        body: JSON.stringify({
          chatSessionId: id
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }) as any;
      return data;
    },

    async getMessage(chatSessionId: string, chatMessageId: string) {
      const { data } = await groupedFunctions.request('interactiveOptimizer', 'getChatMessage', {
        body: JSON.stringify({
          chatSessionId,
          chatMessageId
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }) as any;
      return data;
    },

    async renameHistoryItem(id: string, newName: string) {
      this.isLoadingHistory = true;
      try {
        const index = this.history.findIndex((i) => i.chatSessionId === id);
        if (index > -1) {
          const itemRef = doc(db, 'ioChatSessions', id);
          await updateDoc(itemRef, { name: newName });

          const itemDoc = await getDoc(itemRef);
          this.history.splice(index, 1, itemDoc.data() as ChatSession);
        }
      } finally {
        this.isLoadingHistory = false;
      }
    },

    async deleteHistoryItem(id: string) {
      this.cancelSend();
      this.isLoadingHistory = true;
      if (this.currentChatSessionId === id) {
        this.currentChatSessionId = '';
      }
      try {
        const index = this.history.findIndex((i) => i.chatSessionId === id);
        if (index > -1) {
          const itemRef = doc(db, 'ioChatSessions', id);
          await deleteDoc(itemRef);
          this.history.splice(index, 1);
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoadingHistory = false;
      }
    },

    async transformPromptVec(
      input: string | (string | URL | Blob)[]
    ) {
      if (!Array.isArray(input)) {
        return input;
      }

      const result: (string | object)[] = [];

      for (const x of input) {
        if (typeof x === 'string') {
          result.push(x);
        } else if (x instanceof URL) {
          result.push({
            $$typeof: 'URL',
            data: x.toString(),
          });
        } else if (x instanceof Blob) {
          const b64Str = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (evt) {
              const dataurl = (evt.target?.result as string) || '';
              resolve(dataurl.substring(dataurl.indexOf(',') + 1));
            };
            reader.onerror = reject;
            reader.readAsDataURL(x);
          });
          result.push({
            $$typeof: 'Blob',
            data: b64Str,
            type: x.type,
            encoding: 'base64',
          });
        } else {
          throw new Error(`Unknown type ${typeof x}`);
        }
      }

      return result;
    },

    async downloadImage(url: string) {
      try {
        const result = await fetch(`${apiBaseUrl}/misc/getImageBlob?url=${url}`) as any;
        const blob = await result.blob();
        const a = document.createElement('a');
        const imgUrl = URL.createObjectURL(blob);
        a.href = imgUrl;
        a.download = imgUrl.split('/').pop() || 'interactive.png';
        a.click();
      } catch (error) {
        console.log('error: ', error);
      }
    },

    async downloadImages(images: string[], model: string) {
      try {
        const zip = new JSZip();

        await Promise.all(images.map(async (url, index) => {
          const parsedUrl = decodeURIComponent(url);
          const response = await fetch(`${apiBaseUrl}/misc/getImageBlob?url=${parsedUrl}`);
          const blob = await response.blob();
          const fileName = parsedUrl.split('/').pop();
          zip.file(fileName || `${index}.png`, blob, { binary: true });
        }));

        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `PromptPerfect_${model}_${dayjs(new Date()).format('YYYY-MM-DD')}.zip`);
        });
      } catch (e) {
        console.error(e);
      }
    },

    async pollPendingRequests(options: { taskId: string, status: string, taskType: string, catchError?: boolean, chatSessionId: string, messageId: string; }) {
      const { taskId, status, taskType, catchError = false, chatSessionId, messageId } = options;

      try {
        // console.log('POLLING TASK:', taskId);
        if (chatSessionId != this.currentChatSessionId) {
          // console.log('POLLING DIED');
          return;
        }

        if (['done', 'error', 'stopped'].includes(status)) {
          return;
        }

        const r = await this.checkPendingTask(taskId, taskType);
        // console.log('POLLING BE ANSWER:', r.data);
        const { status: remoteStatus, errorCode, message } = r.data;
        const assistantMessage = this.assistantMessagesCollection[chatSessionId].find(m => m.id === messageId);
        const timeout = this.pollingControllers[taskId];
        clearTimeout(timeout);


        if (['done', 'error', 'stopped'].includes(remoteStatus)) {
          // update message
          const message = await this.getMessage(chatSessionId, messageId);

          if (remoteStatus === 'done') {
            const currentMessage = (assistantMessage?.content as string) ?? '';
            assistantMessage!.content = currentMessage.replace(/<Status>.*?<\/Status>/g, `<Status>done</Status>`);
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
          assistantMessage!.content = (message as any)?.message;
        } else {
          assistantMessage!.content = message;
        }

        if (remoteStatus === 'error') {
          let errorMessage = '';
          switch (errorCode) {
            case '1':
              errorMessage = this.t('interactive.error.already_exist');
              break;
            case '2':
              errorMessage = this.t('interactive.error.controvertial');
              break;
            case '3':
              errorMessage = this.t('interactive.error.invalid_params');
              break;
            case '4':
              errorMessage = this.t('interactive.error.invalid_prompt');
              break;
            default:
              errorMessage = this.t('interactive.error.failed');
              break;
          }
          if (catchError) this.handleError(new Error(errorMessage));
          return;
        }

        if (remoteStatus === 'stopped') {
          assistantMessage!.stopped = true;
          return;
        }

        if (['pending', 'pending-locked', 'processing', 'idle'].includes(remoteStatus)) {
          this.pollingControllers[taskId] = setTimeout(() => {
            this.pollPendingRequests({
              ...options,
              status: remoteStatus,
            });
          }, 5 * 1000);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async loadImageModels() {
      const response = await groupedFunctions.request('interactiveOptimizer', 'getModelList', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    },

    async upscaleImage({ image, chatSessionId, message }: { image: string, chatSessionId: string, message: string; }, target = ChatType.ASSISTANT) {

      this.abortControllers[target] = new AbortController();
      return await groupedFunctions.request('interactiveOptimizer', 'upscaleImage', {
        body: JSON.stringify({
          image,
          chatSessionId,
          message,
        }),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        signal: this.abortControllers[target].signal,
      }) as any;

    },

    async cancelWaitingTask(messageId: string, taskId: string, taskType: string) {
      if (!messageId) return;
      const message = this.assistantMessagesCollection[this.currentChatSessionId as string].find(message => message.id === messageId);
      if (!message) {
        return;
      }
      console.log('canceling task', taskId,);
      const messageStr = message.content;

      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(`<root>${messageStr}</root>`, 'text/html');
      const pendingRequest = htmlDoc.querySelector('PendingRequest');
      console.log('PENDING REQUEST:', pendingRequest);
      if (!pendingRequest) return;

      try {
        // send request to backend to stop
        const result = await groupedFunctions.request('interactiveOptimizer', 'cancelPendingTask', {
          body: JSON.stringify({
            taskId,
            taskType,
          }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log('CANCEL TASK:', result.data);
        clearTimeout(this.pollingControllers[taskId]);
        message.stopped = true;
        message.content = result.data as string;
      } catch (e) {
        this.handleError(e);
      }
    },

    async getMidjourneyUsage() {
      const response = await groupedFunctions.request('interactiveOptimizer', 'getMidjourneyUsage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return response.data;
    }
  },
  persist: {
    paths: ['splitterModel', 'showSidebar', 'currentChatSessionId', 'showedTutorial'],
  },

});
