import { InteractiveExample } from '../types/interactive';
import { USER_PLANS } from './user';

export const ASSISTANT_EXAMPLES: InteractiveExample[] = [
  {
    title: 'Create a cover image',
    content: 'for this tweet.',
    icon: '🖼️',
    category: [USER_PLANS.CREATE_IMAGE],
    exampleContent: '🌟 Discover luxury on a plate in Singapore! 🍴 We\'re thrilled to launch an exquisite lineup of fancy dining dishes — each a unique culinary masterpiece. Indulge in flavours that blend tradition with innovation. Perfect for foodies seeking an unforgettable experience! #GourmetSG #FineDining 🎉',
  },
  {
    title: 'Create a cover image',
    content: 'for this post.',
    icon: '🖼️',
    category: [USER_PLANS.CREATE_IMAGE],
    exampleContent: '🌟 Meet the future on wheels! Introducing our latest electric vehicle - designed for performance & sustainability. Enjoy cutting-edge technology, unmatched efficiency, and a silent, smooth ride that respects the planet. Get ready to electrify your journey! 🚗💨 #DriveElectric #EcoFriendly 🌍',
  },
  {
    title: 'Rephrase this sentence',
    content: 'to a more professional tone.',
    icon: '✍️',
    category: [USER_PLANS.IMPROVE_WRITING],
    exampleContent: 'With the continuous development of AI technology, it has brought more impacts on our lives and work, helping us to improve work efficiency and narrow knowledge barriers.',
  },
  {
    title: 'Revise the writing',
    content: 'and fix grammar or spelling mistakes.',
    icon: '✍️',
    category: [USER_PLANS.IMPROVE_WRITING],
    exampleContent: 'The waether is beauty and how aboat lets go haiking?',
  },
  {
    title: 'Suggest video topics',
    content: 'for my audience.',
    icon: '📃',
    category: [USER_PLANS.TAILOR_CONTENT],
    exampleContent: 'My audience is a group of people who are interested in photography, most of them are young people, interested in the equipment of photography and video and how to use these equipment to shoot excellent works. Please recommend five different video shooting themes to me.',
  },
  {
    title: 'Generate an eye-catching poster',
    content: 'for a fruit-flavored soda.',
    icon: '🎨',
    category: [USER_PLANS.DESIGN_MARKETING]
  },
  {
    title: 'Create AI-enhanced marketing graphics',
    content: 'for a coffee-themed Valentine’s Day.',
    icon: '🎨',
    category: [USER_PLANS.DESIGN_MARKETING]
  },
  {
    title: 'Provide strategies',
    content: 'for enhancing my social engagement.',
    icon: '📈',
    category: [USER_PLANS.BUILD_SOCIAL_MEDIA]
  },
  {
    title: 'Recommend tools',
    content: 'that can help improve social engagement.',
    icon: '🔮',
    category: [USER_PLANS.BUILD_SOCIAL_MEDIA]
  },
  {
    title: 'Craft a welcome email',
    content: 'for announcing a new product.',
    icon: '✉️',
    category: [USER_PLANS.CRAFT_EMAILS]
  },
  {
    title: 'Help me write an email',
    content: 'to customer service',
    icon: '📦',
    category: [USER_PLANS.CRAFT_EMAILS],
    exampleContent: 'requesting a replacement for a keyboard that came damaged.',
  },
  {
    title: 'Brainstorm five intelligent AI features',
    content: 'for an app.',
    icon: '💪',
    category: [USER_PLANS.BUILD_SMART_AI]
  },
  {
    title: 'Compose a tweet',
    content: 'announcing a new eco-friendly shampoo.',
    icon: '🐦',
  },
  {
    title: 'Generate an illustration of',
    content: 'a greetings card for new year.',
    icon: '🏙️',
  },
  {
    title: 'Find the latest updates on',
    content: 'the Mars Rover mission from NASA’s website.',
    icon: '🔍',
  },
  {
    title: 'Please describe',
    content: 'these two images.',
    icon: '🌄',
    images: [
      '/_interactive/assistant_example1.png',
      '/_interactive/assistant_example2.png',
    ]
  },
];

export const OPTIMIZER_EXAMPLES: InteractiveExample[] = [
  {
    title: 'Optimize this prompt for SD-XL: ',
    content: 'A portrait of a cute girl with a luminous dress.',
    icon: '🖼️',
  },
  {
    title: 'Create a prompt for:',
    content: 'a story about a future world.',
    icon: '✏️',
  },
];
