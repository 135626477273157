import { HTTPService } from './base';

export enum PAYMENT_PROVIDER_TYPE {
  WXPAY_QR = 'WxPay-native', // PRC domestic WeChat Pay in QR mode
  WXPAY_WXA = 'WxPay-wxa', // PRC domestic WeChat Pay in wxa mode
  WXPAY_APP = 'WxPay-app', // PRC domestic WeChat Pay in app mode
  WXPAY_WECHAT = 'WxPay-js', // PRC domestic WeChat Pay in js mode
  WXPAY_MOBILE_BROWSER = 'WxPay-h5', // PRC domestic WeChat Pay in h5 mode
  // WECHATPAY = 'WeChatPay', // Non-PRC WeChat Pay
}
export interface InvoiceLite {
  _id: string;

  app: string;

  product: {
    identifier: string;
    name: string;
    displayName?: string;
    description?: string;
  };

  customer: {
    identifier: string;
    oidcUserInfo?: object;
  };

  price: number;

  priceCurrency: string;

  paymentProvider?: {
    type: string;
    identifier: string;
  };

  paymentTransaction?: object;
  paymentMeans?: object;

  productDelivery?: object;

  error?: object;

  paymentRefund?: object;

  status:
    | 'created'
    | 'pending_payment'
    | 'paid'
    | 'pending_delivery'
    | 'delivered'
    | 'pending_refund'
    | 'refunded'
    | 'closed'
    | 'errored';

  createdAt: string;
  paidAt?: string;
  refundAvailableBefore?: string;
  refundedAt?: string;
  updatedA?: string;
  closedAt?: string;
  paymentExpireAt?: string;
}

export interface CoinCounter {
  _id: string;
  customer: { app: string; identifier: string }[];
  total: number;
  remaining: number;

  badges?: { [k: string]: CoinBadge };

  createdAt: string;
  updatedAt: string;
}

export interface CoinBadge {
  _id: string;

  account: string;

  name: string;

  metadata?: { [k: string]: any };

  createdAt: string;

  updatedAt?: string;
  expireAt?: string;
}

export interface CoinTransactionRecord {
  _id: string;

  account: string;
  category: string;
  description?: string;
  metadata?: { [k: string]: any };
  amount: number;
  status: 'pending' | 'confirmed' | 'reverted';

  createdAt: string;
  updatedAt?: string;
  pendingUntil?: string;
}

export type IntegrityEnvelopeWrapped<T, MT extends object = any> = {
  code: number;
  status: number;
  message: string;
  data: T;
  meta: MT;
};

export class APIHubble extends HTTPService {
  constructor() {
    super(
      'https://api.hubble.jina.ai'
      // 'https://apihubble.staging.jina.ai',
    );
  }

  listInvoices(
    idToken: string,
    pagination = { pageIndex: 1, pageSize: 10, sort: '-createdAt' }
  ) {
    return this.postJson<
      IntegrityEnvelopeWrapped<
        InvoiceLite[],
        { total: number; pageIndex: number; pageSize: number }
      >
    >('/v2/rpc/invoiceLite.list', {
      ...pagination,
      idToken,
    });
  }
  getSingleInvoice(idToken: string, invoiceId: string) {
    return this.postJson<IntegrityEnvelopeWrapped<InvoiceLite>>(
      '/v2/rpc/invoiceLite.getSingle',
      {
        invoice: invoiceId,
        idToken,
      }
    );
  }
  paySingleInvoice(
    idToken: string,
    invoiceId: string,
    paymentProvider: PAYMENT_PROVIDER_TYPE,
    paymentProviderIdentifier?: string
  ) {
    return this.postJson<IntegrityEnvelopeWrapped<any>>(
      '/v2/rpc/invoiceLite.pay',
      {
        idToken,
        invoice: invoiceId,
        paymentProvider,
        paymentProviderIdentifier,
      }
    );
  }
  closeSingleInvoice(idToken: string, invoiceId: string) {
    return this.postJson<IntegrityEnvelopeWrapped<InvoiceLite>>(
      '/v2/rpc/invoiceLite.close',
      {
        invoice: invoiceId,
        idToken,
      }
    );
  }

  async *subscribeInvoiceStatus(idToken: string, invoiceId: string) {
    const r = await this.postJson(
      '/v2/rpc/invoiceLite.subscribeInvoiceStatus',
      {
        invoice: invoiceId,
        idToken,
      },
      { responseType: 'stream' }
    );

    if (!r.body) {
      return;
    }

    const reader = r.body.getReader();

    // Read the stream as text
    const decoder = new TextDecoder('utf-8');

    const cancel = () => reader.cancel();

    let text = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      // Decode the chunk and append to the text buffer
      text += decoder.decode(value);

      // Split the text buffer into lines
      const lines = text.split('\n');

      // Parse each line as JSON and pass it to the onData callback
      for (const line of lines) {
        if (!line) continue; // Skip empty lines
        const data = JSON.parse(line);

        try {
          yield [data, cancel];
        } catch (err) {
          cancel();
          break;
        }
      }

      // Keep any remaining text in the buffer for the next iteration
      text = lines.pop() || '';
    }
  }

  bindExternalAccounts(...idTokens: string[]) {
    return this.postJson<IntegrityEnvelopeWrapped<boolean>>(
      '/v2/rpc/coinCounter.bindExternalAccounts',
      {
        idTokens,
      }
    );
  }

  async getAccountDetail(idToken: string) {
    const r = await this.postJson<IntegrityEnvelopeWrapped<CoinCounter>>(
      '/v2/rpc/coinCounter.getAccountDetail',
      {
        idToken,
      }
    );

    return r;
  }

  async listTransactions(
    idToken: string,
    page: number = 1,
    pageSize: number = 20
  ) {
    const r = await this.postJson<
      IntegrityEnvelopeWrapped<CoinTransactionRecord[]>
    >('/v2/rpc/coinCounter.listTransactions', {
      idToken,
      pageIndex: page,
      pageSize,
    });

    return r;
  }

  async getTransactionDetail(idToken: string, transactionId: string) {
    const r = await this.postJson<
      IntegrityEnvelopeWrapped<CoinTransactionRecord>
    >('/v2/rpc/coinCounter.getTransactionDetail', {
      transaction: transactionId,
      idToken,
    });

    return r;
  }

  async *subscribeAccountTransferStatus(idToken: string) {
    const r = await this.postJson(
      '/v2/rpc/coinCounter.subscribeTransferStatus',
      {
        idToken,
      },
      { responseType: 'stream' }
    );

    if (!r.body) {
      return;
    }

    const reader = r.body.getReader();

    // Read the stream as text
    const decoder = new TextDecoder('utf-8');

    const cancel = () => reader.cancel();

    let text = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      // Decode the chunk and append to the text buffer
      text += decoder.decode(value);

      // Split the text buffer into lines
      const lines = text.split('\n');

      // Parse each line as JSON and pass it to the onData callback
      for (const line of lines) {
        if (!line) continue; // Skip empty lines
        const data = JSON.parse(line);

        try {
          yield [data, cancel];
        } catch (err) {
          cancel();
          break;
        }
      }

      // Keep any remaining text in the buffer for the next iteration
      text = lines.pop() || '';
    }
  }
}
