import { defineStore } from 'pinia';
import { logEvent } from 'firebase/analytics';
import { Notify } from 'quasar';
import { analytics } from 'src/fb';

import { groupedFunctions } from '../fb';
import { TOUR_ACHIEVEMENTS, TOUR_FEATURES } from 'src/constants';
import { differenceBy } from 'lodash-es';
import { useUserStore } from './user-store';

interface AchievementRecord {
  id: string;
  icon: string;
  achieved: boolean;
  total: number;
  progress: number;
}

interface State {
  achievements: AchievementRecord[];
  unlockAll: boolean;
}

const DEFAULT_ACHIEVEMENTS = [
  {
    id: 'finish-prompt-tutorial',
    icon: 'cake',
    achieved: false,
    progress: 0,
    total: 1,
  },
  {
    id: 'finish-prompt-tutorial-image',
    icon: 'cake',
    achieved: false,
    progress: 0,
    total: 1,
  },
  {
    id: 'finish-prompt-tutorial-add-ons',
    icon: 'cake',
    achieved: false,
    progress: 0,
    total: 1,
  },
  {
    id: 'finish-prompt-tutorial-variables',
    icon: 'cake',
    achieved: false,
    progress: 0,
    total: 1,
  },
  {
    id: 'first-optimize',
    icon: 'cake',
    total: 1,
    progress: 0,
    achieved: false,
  },
  {
    id: '10x-optimize',
    icon: 'replay_10',
    achieved: false,
    progress: 0,
    total: 10,
  },
  {
    id: 'chatgpt-optimize',
    progress: 0,
    total: 1,
    icon: 'question_answer',
    achieved: false,
  },
  {
    id: 'dalle-optimize',
    progress: 0,
    total: 1,
    icon: 'image',
    achieved: false,
  },
  {
    id: 'sd-optimize',
    progress: 0,
    total: 1,
    icon: 'image',
    achieved: false,
  },
  {
    id: 'midjourney-optimize',
    progress: 0,
    total: 1,
    icon: 'image',
    achieved: false,
  },
  {
    id: 'claude-optimize',
    progress: 0,
    total: 1,
    icon: 'bakery_dining',
    achieved: false,
  },
  {
    id: 'gpt4-optimize',
    progress: 0,
    total: 1,
    icon: 'text_fields',
    achieved: false,
  },
  {
    id: 'kandinsky-optimize',
    progress: 0,
    total: 1,
    icon: 'image',
    achieved: false,
  },
  {
    id: 'enabled-preview',
    progress: 0,
    total: 10,
    icon: 'preview',
    achieved: false,
  },
  {
    id: 'enabled-multilang',
    progress: 0,
    total: 1,
    icon: 'language',
    achieved: false,
  },
  {
    id: 'all-achievements',
    icon: 'verified',
    total: 1,
    progress: 0,
    achieved: false,
  },
];

export const useAchievementStore = defineStore('achievement', {
  state: (): State => ({
    achievements: DEFAULT_ACHIEVEMENTS,
    unlockAll: false,
  }),

  getters: {
    // check if all targets are achieved except 'all-achievements'
    allAchieved: (state) => {
      return state.achievements
        .filter((a) => a.id !== 'all-achievements')
        .every((a) => a.achieved);
    },

    getAchievementById: (state) => (id: string) => {
      return state.achievements.filter((item) => item.id === id)[0];
    },
    hasOnBoarded: (state) => {
      return !!state.achievements.filter((item) => item.id === TOUR_ACHIEVEMENTS[TOUR_FEATURES.TEXT])[0]?.achieved || !!state.achievements.filter((item) => item.id === TOUR_ACHIEVEMENTS[TOUR_FEATURES.IMAGE])[0]?.achieved;
    },
  },

  actions: {
    achieve(id: string) {
      if (this.achievements.find((item) => item.id === id)) {
        const achievement = this.achievements.filter(
          (item) => item.id === id
        )[0];
        achievement.achieved = true;
      }

      // check if all achievements are unlocked
      // if all-achievements is not achieved, that means we should show the unlock button for the user to trigger this final achievement
      if (this.getAchievementById('all-achievements').achieved) {
        this.unlockAll = true;
      }
    },

    async update(id: string) {
      const userStore = useUserStore();

      // sync default achievement list with local storage
      if(this.achievements.length !== DEFAULT_ACHIEVEMENTS.length) {
        const results = differenceBy(DEFAULT_ACHIEVEMENTS, this.achievements, 'id');
        this.achievements.push(...results);
      }

      const achievement = this.achievements.find((item) => item.id === id);
      if (!achievement) {
        console.error(`Achievement ${id} not found`);
        return;
      }

      // If already achieved, return
      if (achievement.achieved) {
        return;
      }

      // If it's not completed, return
      achievement.progress += 1;
      if (achievement.progress < achievement.total) {
        return;
      }

      if (achievement.id === 'all-achievements') {
        this.unlockAll = true;
      }

      logEvent(analytics, 'PP_achievement_unlock', {
        achievementId: achievement.id,
      });

      // Achievements don't have bonus for RateLimit users
      if (userStore.isRateLimit) {
        achievement.achieved = true;
        return;
      }


      try {
        const {
          data: { givenCreditAmount },
        } = await groupedFunctions.call<{ givenCreditAmount: number }>(
          'account',
          'unlockAchievement',
          { achievementId: achievement.id }
        );

        Notify.create({
          caption: `${this.t(
            'achievement_unlocked'
          )} ${givenCreditAmount} ${this.t('credits')}`,
          message: this.t(achievement.id + '-fancyName'),
          icon: achievement.icon,
          color: 'secondary',
          textColor: 'black',
          actions: [
            this.router
              ? {
                  label: this.t('see_all'),
                  color: 'black',
                  handler: () => {
                    this.router.push({ path: '/billing' });
                  },
                }
              : {},
          ],
        });
      } catch (e) {
        this.handleError(e);
      } finally {
        achievement.achieved = true;
      }
    },
  },

  persist: true,
});
