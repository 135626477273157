import { initializeApp } from 'firebase/app';
import { initializeAnalytics } from 'firebase/analytics';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { GroupedFirebaseFunctionCompatLayer } from 'src/shared/api/wrapper';

import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD8ts1fEl_7ubrhwa2oeQUGxhYS12xgQb8',
  authDomain: window.location.hostname.startsWith('localhost')
    ? 'prompt-ops.firebaseapp.com'
    : window.location.hostname,
  projectId: 'prompt-ops',
  storageBucket: 'prompt-ops.appspot.com',
  messagingSenderId: '638671243057',
  appId: '1:638671243057:web:4a9a2439e6cf10238c5210',
  measurementId: 'G-EXFL7DCBKN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const analytics = initializeAnalytics(app, {
  config: {
    send_page_view: false,
  }
});

const EMULATE = Boolean(globalThis.process.env.FIREBASE_EMULATE);

export let apiBaseUrl = 'https://us-central1-prompt-ops.cloudfunctions.net';

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    '6Lds5zQpAAAAAJpfj6xkNeCe14TvhQK5FNrgroyB'
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

if (EMULATE) {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 9098);
  connectAuthEmulator(auth, 'http://localhost:9099');

  apiBaseUrl = 'http://localhost:5001/prompt-ops/us-central1';
}

class GroupedFirebaseFunction extends GroupedFirebaseFunctionCompatLayer {
  getIdToken(): Promise<string> {
    return auth.currentUser?.getIdToken() ?? Promise.resolve('');
  }
}

export const groupedFunctions = new GroupedFirebaseFunction(apiBaseUrl);
