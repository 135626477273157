<template>
  <q-dialog id="planExpiredDialog">
    <DialogContent close-icon :separator="false">
      <template #header>
        <div class="text-h5 text-weight-bold q-pl-md">
          {{ t('new_plan_dialog.expires_title') }}
        </div>
      </template>

      <q-card-section class="q-mb-md">
        <div>
          {{ t('new_plan_dialog.expires_description_1') }}
        </div>
        <div>
          {{ t('new_plan_dialog.expires_description_2') }}
        </div>
      </q-card-section>

      <template #footer>
        <q-space />
        <q-btn class="q-my-xs q-mr-md q-mb-md" flat unelevated color="primary" :label="t('ok_let_us_go')" v-close-popup></q-btn>
      </template>

    </DialogContent>
  </q-dialog>
</template>

<script setup lang="ts">
import DialogContent from '../DialogContent.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>