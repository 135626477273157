import { defineStore } from 'pinia';
import { MODALITY_TABS } from '../constants';

interface State {
  /**
   * Current prompt text
   */
  currentPrompt: string;
  modalityTab?: MODALITY_TABS;
  /**
   * Default model list if user doesn't have settled up model list before
   */
  defaultModelList: string[];
  /**
   * Model list set by user before
   */
  modelList: string[];
  /**
   * History list of finished arena run
   */
  history: string[];
  /**
   * On-going requests
   */
  ongoingRequests: any[];
  /**
   * Timeout for the arena request
   */
  timeout: number;
}

export const useArenaStore = defineStore('arena', {
  state: (): State => ({
    currentPrompt: '',
    modalityTab: MODALITY_TABS.TEXT,
    defaultModelList: [
      'gpt-4o',
      'gpt-3.5-turbo',
      'claude-3-sonnet',
      'llama3-70b-instruct',
      'cogenerate',
    ],
    modelList: [],
    history: [],
    ongoingRequests: [],
    timeout: 60,
  }),

  getters: {
    isBusy(state) {
      return state.ongoingRequests.some((item) => item.isBusy);
    },
  },

  actions: {},

  persist: {
    paths: ['currentPrompt', 'modalityTab', 'modelList', 'history', 'timeout'],
  },
});
