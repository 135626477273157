import { RouteRecordRaw } from 'vue-router';

import { groupedFunctions } from '../fb';
import featureToggle from '../featureToggle';
import { ref } from 'vue';

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
    requiresAuth?: boolean;
    icon?: string;
    category?: string;
    visible?: boolean;
    invisibleWhenRateLimit?: boolean;
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'landing layout',
    component: () => import('layouts/LandingLayout.vue'),
    children: [
      {
        name: 'landing',
        path: '',
        component: () => import('pages/LandingPage.vue'),
      },
      {
        name: 'blog',
        path: '/blogs/:slug',
        component: () => import('pages/BlogPage.vue'),
      }
    ],
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    name: 'main',
    children: [
      {
        name: 'home',
        path: '/home',
        component: () => import('pages/HomePage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'share',
        path: '/share',
        component: () => import('pages/SharePage.vue'),
        meta: {
          requiresAuth: false,
          title: 'Share',
          description: 'Shared prompts with your friends and colleagues.',
        },
      },
      {
        name: 'interactive',
        path: '/interactive',
        component: () => import('pages/InteractivePage.vue'),
        meta: {
          requiresAuth: true,
          title: 'interactive.name',
          icon: 'forum',
          category: featureToggle['interactiveOptimizer'] ? 'features' : '',
          visible: featureToggle['interactiveOptimizer'],
        },
        alias: '/chat'
      },
      {
        name: 'prompts',
        path: '/prompts',
        component: () => import('pages/PromptsPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Prompts',
          icon: 'app_registration',
          category: 'features',
          visible: true,
        },
      },
      {
        name: 'arena',
        path: '/arena',
        component: () => import('pages/PlaygroundPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'arena',
          icon: 'stadium',
          category: 'features',
          visible: true,
        },
      },
      {
        name: 'template',
        path: '/services',
        component: () => import('pages/ServicesPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Prompt_as_service',
          icon: 'webhook',
          category: 'features',
          visible: true,
        },
      },
      {
        name: 'agents',
        path: '/agents',
        component: () => import('pages/AgentsPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'agents',
          icon: 'smart_toy',
          category: featureToggle['agentsPage'] ? 'features' : '',
          visible: featureToggle['agentsPage'],
        },
      },
      {
        name: 'streamline',
        path: '/streamline',
        component: () => import('pages/StreamlinePage.vue'),
        meta: {
          requiresAuth: true,
          title: 'streamline',
          icon: 'account_tree',
          category: 'features',
          visible: false,
        },
      },
      {
        name: 'fewshot',
        path: '/fewshot',
        component: () => import('pages/FewShotPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'fewshot_prompt',
          icon: 'category',
          category: 'features',
          visible: false,
        },
      },
      {
        name: 'databases',
        path: '/databases',
        component: () => import('pages/DatabasePage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Databases',
          icon: 'mdi-database-outline',
          category: 'features',
          visible: false,
        },
      },
      {
        name: 'functions',
        path: '/functions',
        component: () => import('pages/FunctionsPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'functions',
          icon: 'mdi-function-variant',
          category: 'features',
          visible: false,
        },
      },
      {
        name: 'ensembles',
        path: '/ensembles',
        component: () => import('pages/EnsemblesPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'ensemble_models',
          icon: 'widgets',
          category: 'features',
          visible: false,
        },
      },
      {
        name: 'settings',
        path: '/settings',
        component: () => import('pages/SettingsPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Settings',
          icon: 'tune',
          category: 'config',
          visible: true,
        },
      },
      {
        name: 'api',
        path: '/api',
        component: () => import('pages/ApiPage.vue'),
        meta: {
          requiresAuth: false,
          title: 'api',
          icon: 'api',
          category: 'config',
          visible: true,
        },
      },
      {
        name: 'billing',
        path: '/billing',
        component: () => import('pages/BillingPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Billing',
          icon: 'person',
          category: 'config',
          visible: true,
          invisibleWhenRateLimit: true
        },
      },
      {
        name: 'affiliate',
        path: '/a/:affiliateId',
        beforeEnter: (to, from, next) => {
          console.log('affiliate', to.params.affiliateId);
          if (to.params.affiliateId) {
            localStorage.setItem(
              'affiliateId',
              to.params.affiliateId as string
            );
            groupedFunctions
              .call('misc', 'affiliateLinkAccess', {
                affiliateId: to.params.affiliateId,
              })
              .catch(console.error);
          }

          next('/');
        },
        component: () => import('pages/HomePage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'help',
        path: '/help',
        component: () => import('pages/HelpPage.vue'),
        meta: {
          requiresAuth: true,
          title: 'Help',
          icon: 'help_center',
          category: 'config',
          visible: true,
        },
      },
      {
        name: 'affiliate-program',
        path: '/affiliate',
        component: () => import('pages/AffiliatePage.vue'),
        meta: {
          requiresAuth: false,
          title: 'join_affiliate_program1',
          icon: 'diversity_3',
          category: 'config',
          visible: true,
        },
        beforeEnter: (to, from, next) => {
          next('/');
        }
      },
    ],
  },
  {
    path: '/',
    name: 'blank layout',
    component: () => import('layouts/BlankLayout.vue'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('pages/LoginPage.vue'),
      },
      {
        name: 'on-boarding',
        path: '/on-boarding',
        component: () => import('pages/OnBoardingPage.vue'),
        meta: {
          requiresAuth: true,
        }
      },
    ],
  },
  {
    name: 'connect',
    path: '/connect',
    component: () => import('src/shared/pages/connect/ConnectPage.vue'),
  },
];

// Always leave this as last one to catch all
routes.push({
  path: '/:catchAll(.*)*',
  component: () => import('layouts/LandingLayout.vue'),
  children: [
    {
      path: '',
      component: () => import('pages/LandingPage.vue'),
    },
  ],
});

export default routes;

const mainRoutes = routes.find((route) => route.name === 'main');
export const featuresRoutes = ref(mainRoutes?.children?.filter((route) => route.meta?.category === 'features') ?? []);
const configBlackList = ['affiliate-program'];
export const configsRoutes = ref(mainRoutes?.children?.filter((route) => route.meta?.category === 'config' && !configBlackList.includes(route.name)) ?? []);

export const toggleOldVersionRoutes = (isRateLimit: boolean) => {
  const allRoutes = mainRoutes?.children ?? [];
  featuresRoutes.value = allRoutes.filter((route) => route.meta?.category === 'features');
  configsRoutes.value = allRoutes
    .filter((route) => route.meta?.category === 'config' && !configBlackList.includes(route.name))
    .filter((route) => {
      if (isRateLimit) {
        return !route.meta?.invisibleWhenRateLimit
      }

      return true;
    });
}
