import { defineStore } from 'pinia';
import { unionWith } from 'lodash-es';
import { db, groupedFunctions } from 'src/fb';
import { EnsembleModel, EnsembleModelOptions } from 'src/types/ensemble';
import { STREAM_STATUS } from '../utils/stream';
import { FilterOptions, SortByOptions, supportedModels } from '../constants';
import {
  collection,
  getDocs,
  onSnapshot,
  or,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useUserStore } from './user-store';

export const DEFAULT_PROMPT_TEMPLATE = `Input: $\{INPUT\}

Combined Output:
$\{COMBINED\}

Aggregator Synthesis:
Considering the diverse perspectives from the Component Models, the synthesized response would ideally:
- Highlight commonalities across models.
- Resolve conflicting information or perspectives.
- Emphasize key insights or robust conclusions.
- Maintain coherence and consistency in the final output.

Please synthesize the responses from the Component Models to create a comprehensive, cohesive final output.
`;

export const PROMPT_TEMPLATE_VARIABLES = [
  'INPUT',
  'COMBINED',
  'COMPONENTS[0-n]',
];

interface State {
  publicEnsembleModels: EnsembleModel[];
  myEnsembleModels: EnsembleModel[];
  modelSettings: Record<string, EnsembleModelOptions>;
  results: Record<string, Result>;
  loading: boolean;
  deletingModels: Record<string, boolean>;
  updatingModels: Record<string, boolean>;
  filterOptions: FilterOptions[];
  sortOption: SortByOptions;
  listingEnsembleModels: boolean;
}

interface Result {
  status: STREAM_STATUS;
  data: any[];
  timeElapsed: number;
}

export const useEnsembleStore = defineStore('ensemble', {
  state: (): State => ({
    publicEnsembleModels: [],
    myEnsembleModels: [],
    modelSettings: {},
    results: {},
    loading: false,
    deletingModels: {},
    updatingModels: {},
    filterOptions: [FilterOptions.MINE_ONLY, FilterOptions.COMMUNITY_ONLY],
    sortOption: SortByOptions.TIME,
    listingEnsembleModels: false,
  }),

  getters: {
    ensembleModels: (state) => {
      return (
        unionWith(
          state.myEnsembleModels,
          state.publicEnsembleModels,
          (item1, item2) => item1._id === item2._id
        ) || []
      );
    },
  },

  actions: {
    async listEnsembleModels() {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      try {
        this.listingEnsembleModels = true;
        const [myResult, publicResult] = await Promise.all(
          [
            {fnName: 'listMyCustomLLM', pageSize: 5000},
            {fnName:  'listAllPublicCustomLLM', pageSize: 10000}
          ]
          .map(({fnName, pageSize}) =>
            groupedFunctions.call<EnsembleModel[]>('customLLM', fnName, { pageSize })
              .then((res) => res.data)
          )
        );

        this.publicEnsembleModels = publicResult.filter(
          (item) => item.uid !== user?.uid
        );
        this.myEnsembleModels = myResult;
      } catch (error: any) {
        throw error;
      } finally {
        this.listingEnsembleModels = false;
      }
    },

    async upsertModel(model: EnsembleModel) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      if (model._id) {
        this.updatingModels[model._id] = true;
      }

      const payload = {
        infoModels: model.infoModels,
        ensembleModel: model.ensembleModel,
        ensemblePromptTemplate: model.ensemblePromptTemplate,
        name: model.name,
        type: model.type,
        infoTimeoutMs: model.infoTimeoutMs,
        openToPublic: model.openToPublic,
      };
      try {
        const result = await groupedFunctions.call<EnsembleModel>(
          'customLLM',
          'upsertCustomLLM',
          {
            customLLM: payload,
            id:
              !model._id || model._id.startsWith('new_model_')
                ? undefined
                : model._id,
          }
        );
        const data = result.data;
        const index = this.myEnsembleModels.findIndex(
          (item) => item._id === model._id
        );
        if (model._id === data._id) {
          this.myEnsembleModels[index] = data;
        } else {
          this.myEnsembleModels.splice(index, 1, data);
        }
        return data;
      } catch (error: any) {
        this.handleError(error);
        throw error;
      } finally {
        if (model._id) {
          this.updatingModels[model._id] = false;
        }
      }
    },

    async getModel(id: string) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      try {
        const model = (
          await groupedFunctions.call<EnsembleModel>(
            'customLLM',
            'getSingleCustomLLM',
            { id }
          )
        ).data;
        return model;
      } catch (error: any) {
        this.handleError(error);
        throw error;
      }
    },

    async deleteModel(id: string) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      this.deletingModels[id] = true;
      const index = this.myEnsembleModels.findIndex((item) => item._id === id);
      try {
        await groupedFunctions.call('customLLM', 'deleteSingleCustomLLM', {
          id,
        });
        this.myEnsembleModels.splice(index, 1);
      } catch (error: any) {
        this.handleError(error);
        throw error;
      } finally {
        this.deletingModels[id] = false;
      }
    },

    async runModel(prompt: string, modelId: string) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      let currentTimeout = 0;
      const timeoutStat = setInterval(() => {
        currentTimeout += 1;
      }, 100);
      try {
        this.results[modelId] = {
          data: [],
          status: STREAM_STATUS.RUNNING,
          timeElapsed: 0,
        };
        const modelInfo = this.ensembleModels.find(
          (item) => item._id === modelId
        );
        const isStream =
          supportedModels.find(
            (m) => m.name === modelInfo?.ensembleModel._model
          )?.stream === false
            ? false
            : true;

        const response = await groupedFunctions.request('customLLM', 'runCustomLLM', {
          body: JSON.stringify({
            prompt,
            model: modelId,
            options: {
              stream: isStream,
              ...this.modelSettings[modelId],
            }
          }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (isStream) {
          for await (const event of response as unknown as AsyncGenerator<any>) {
            this.results[modelId].data.push(event.data);
            this.results[modelId].timeElapsed = currentTimeout / 10;
          }
          this.results[modelId].status = STREAM_STATUS.DONE;
        } else {
          this.results[modelId].data = [(response as any).data];
          this.results[modelId].timeElapsed = currentTimeout / 10;
          this.results[modelId].status = STREAM_STATUS.DONE;
        }
      } catch (err) {
        this.results[modelId].status = STREAM_STATUS.ERROR;
        this.handleError(err);
      } finally {
        clearInterval(timeoutStat);
      }
    },

    watchEnsembleSystem() {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      const result = query(
        collection(db, 'custom-llm'),
        or(
          where('uid', '==', user.uid),
          where('openToPublic', '==', true)
        )
      );
      return onSnapshot(result, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const id = change.doc.id;
          const uid = change.doc.data().uid;
          if (uid === user.uid) {
            const index = this.myEnsembleModels.findIndex((m) => m?._id === id);
            if (index > -1) {
              this.myEnsembleModels[index].timesRan =
                change.doc.data().timesRan;
            }
          } else if (change.doc.data().openToPublic) {
            const index = this.publicEnsembleModels.findIndex(
              (m) => m._id === id
            );
            if (index > -1) {
              this.publicEnsembleModels[index].timesRan =
                change.doc.data().timesRan;
            }
          }
        });
      });
    },

    async getHistory(modelId: string) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      const collectionRef = collection(
        db,
        'users',
        user.uid,
        'ensembleModelsHistory',
        modelId,
        'history'
      );
      const q = query(collectionRef, orderBy('timestamp', 'desc'));

      const snapshot = await getDocs(q);

      const history = snapshot.docs.map((doc) => {
        return {
          query: doc.data().input,
          response: doc.data().finalOutputFromEnsembleModel,
          createdAt:
            doc.data().timestamp.seconds * 1000 +
            doc.data().timestamp.nanoseconds / 1000000,
          list: doc.data().infoModelOutputs.map((item: any) => ({
            name: item.model,
            response: item.output,
          })),
        };
      });
      return {
        data: history,
        meta: {
          total: history.length,
        },
      };
    },

    resetResult(modelId: string) {
      this.results[modelId] = {
        data: [],
        status: STREAM_STATUS.STATIC,
        timeElapsed: 0,
      };
    },

    reset() {
      this.myEnsembleModels = [];
      this.publicEnsembleModels = [];
      this.loading = false;
      this.results = {};
      this.deletingModels = {};
      this.updatingModels = {};
      // don't clear modelSettings since it doesn't stored to BE
    },
  },
  persist: {
    paths: [
      'myEnsembleModels',
      'publicEnsembleModels',
      'modelSettings',
      'filterOptions',
      'sortOption',
    ],
  },
});
