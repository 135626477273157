import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import { logEvent } from 'firebase/analytics';

import { analytics } from 'src/fb';
import { useUserStore } from 'stores/user-store';
import { useTourStore } from 'stores/tour';
import { version } from '../../package.json';

import routes, { featuresRoutes } from './routes';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(async () => {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
    ? createWebHistory
    : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: (to) => {
      if (to.hash) {
        return { el: to.hash, behavior: 'smooth' }
      }
     return { left: 0, top: 0 }
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  // Navigate based on user status
  Router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth && useUserStore().user === null) {
      next({ name: 'landing', query: to.query });
      return;
    }

    if (to.meta.invisibleWhenRateLimit && useUserStore().isRateLimit) {
      next({ name: 'interactive', query: from.query })
      return;
    }

    if (useUserStore().user !== null) {
      await useUserStore().init();
      if (useUserStore().needsOnBoarding && to.name !== 'on-boarding') {
        next({ name: 'on-boarding', query: to.query });
        return;
      }
      if (to.query?.page && to.query.page !== to.name && featuresRoutes.value.some((route) => route.name === to.query.page)) {
        next({ name: to.query.page as string, query: to.query });
        return;
      }

      if (to.name === 'landing' || (!useUserStore().needsOnBoarding && to.name === 'on-boarding')) {
        next({ name: 'interactive' });
        return;
      }
    }

    next();
  });

  Router.afterEach((to) => {
    useTourStore().cancelTour.complete()

    logEvent(analytics, 'page_view', {
      page_location: document.location.href,
      page_title: document.title,
      page_path: to.path,
      version: version,
    });
  });

  Router.onError((error, to) => {
    console.log(`--- Error in router ${to.fullPath}---`, error)
    const IMPORT_ERRORS = [
      'failed to fetch dynamically imported module',
      'importing a module script failed',
      'error loading dynamically imported',
      'unable to preload css',
    ];
    if (IMPORT_ERRORS.some((err) => error.message?.toLowerCase()?.includes(err)) &&
    (Date.now() - useUserStore().lastRefreshedAt) > 1000 * 60 * 5) {
      useUserStore().lastRefreshedAt = Date.now();
      window.location.href = to.fullPath || window.location.href;
    }
  });

  return Router;
});
