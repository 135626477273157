export type MessageSource = {
  url: string;
  title?: string;
  desc?: string;
  icon?: string;
};

export type Message = {
  content: string | (string | URL | Blob)[];
  role: 'user' | 'assistant' | string;
  sources?: MessageSource[];
  images?: { url: string; }[];
  reference?: {
    type: string;
    value: string[];
  };
  [key: string]: any;
};

export type ChatSession = {
  chatSessionId: string;
  createdAt: string;
  uid: string;
  chatModel: string;
  imageModel: string;
  name: string;
};

export enum ChatType {
  ASSISTANT = 'assistant',
  OPTIMIZER = 'optimizer',
}

type MessageContentChild = {
  id: string;
  modality: 'text' | 'image';
  model?: string;
  modelOptions?: {
    name: string;
    icon?: string;
  };
};

export type OptimizerMessageContentChild = {
  prompt: string;
  questions: string;
} & MessageContentChild;

export type AssistantMessageContentChild = {
  url?: string;
} & MessageContentChild;

export type MessageContent<T extends ChatType> = {
  root: string;
  modality: 'text' | 'image';
  children?: Array<T extends ChatType.ASSISTANT ? AssistantMessageContentChild : OptimizerMessageContentChild>;
  [key: string]: any;
};

export type PreviewOptions = {
  prompt: string;
  model?: string;
  modality?: string;
  imageModel?: string;
};

export type SendingMessageOptions = PreviewOptions & {
  action: string;
  images?: string[];
};

export interface InteractiveExample {
  title: string;
  content: string;
  icon: string;
  images?: string[];
  category?: string[];
  exampleContent?: string;
  tag?: string;
  tagTooltip?: string;
  tagIcon?: string;
  data?: string;
  dataIcon?: string;
  dataTooltip?: string;
}
