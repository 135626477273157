import { defineStore } from 'pinia';
import Shepherd from 'shepherd.js';
import { offset, size, shift } from '@floating-ui/dom';
import { logEvent } from 'firebase/analytics';
import { scroll } from 'quasar';
import { nextTick } from 'vue';
import { analytics } from 'src/fb';

import { useUserStore } from './user-store';
import { examples, useAutoTuneStore } from './auto-tune';
import { useAchievementStore } from './achievement';
import { MODALITY_TABS, TOUR_ACHIEVEMENTS, TOUR_OPTIMIZE_FEATURES, supportedModels, TOUR_FEATURES } from '../constants';
import { useInteractiveStore } from './interactive';
import { ChatType } from '../types/interactive';

type AutoTuneFeature = TOUR_FEATURES.ADDON | TOUR_FEATURES.IMAGE | TOUR_FEATURES.TEXT | TOUR_FEATURES.VARIABLES;
interface State {
  autoTuneTour: Shepherd.Tour;
  interactiveTour: Shepherd.Tour;
  cancelTour: Shepherd.Tour;

  textOptimizerTour: Shepherd.Tour;
  imageOptimizerTour: Shepherd.Tour;
  addonTour: Shepherd.Tour;
  variablesTour: Shepherd.Tour;

  activeAutoTuneTour?: Shepherd.Tour;

  /**
   * List of router paths that needs to display tour icons on top-right of the header
   */
  displayTourIcons: string[];

  /**
   * Whether the cancel tour has been shown
   */
  hasShownCancelTour: boolean;

  assistantTour: Shepherd.Tour | null;
  optimizerTour: Shepherd.Tour | null;
}

const { setVerticalScrollPosition, getScrollHeight } = scroll;

export const useTourStore = defineStore('tour', {
  persist: {
    paths: [
      'hasShownCancelTour',
    ],
  },
  state: (): State => {

    const [
      autoTuneTour,
      textOptimizerTour,
      imageOptimizerTour,
      addonTour,
      variablesTour,
      interactiveTour
    ] = Array.from({ length: 6 }, () => new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: { behavior: 'smooth', block: 'nearest' },
        arrow: false,
        floatingUIOptions: {
          middleware: [
            offset({
              mainAxis: 10,
            }),
          ],
        },
      },
    }));

    const cancelTour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: { behavior: 'smooth', block: 'nearest' },
        arrow: false,
        floatingUIOptions: {
          middleware: [
            offset({
              mainAxis: 10,
            }),
          ],
        },
      },
    });

    return {
      autoTuneTour,
      textOptimizerTour,
      imageOptimizerTour,
      addonTour,
      variablesTour,
      interactiveTour,
      cancelTour,
      activeAutoTuneTour: undefined,

      displayTourIcons: ['interactive', 'prompts'],
      hasShownCancelTour: false,
      assistantTour: null,
      optimizerTour: null,
    };
  },

  actions: {
    getAutoTuneWelcomeStep({
      item,
      tour,
      hasFormerStep = false,
    }: {
      item: AutoTuneFeature,
      tour: Shepherd.Tour;
      hasFormerStep?: boolean;
    }): Shepherd.Step.StepOptions {
      const userStore = useUserStore();

      return {
        text:
          `<h4>${this.t(`tutorial.task_${item}`)}</h4>` +
          `<p>${this.t(`tutorial.${item}_welcome`)}${!userStore.isRateLimit && this.te(`tutorial.${item}_welcome_credit`) ? ` ${this.t(`tutorial.${item}_welcome_credit`)}` : ''}</p>`,
        classes: 'tour-task-welcome tour-justify-between',
        id: 'feature_welcome',
        ...(!hasFormerStep && {
          cancelIcon: {
            enabled: true,
          },
        }),
        buttons: [
          {
            text: () => this.t('tutorial.back'),
            action: () => {
              logEvent(analytics, `PP_on_boarding_back_welcome_from_${item}`, {
                user_id: userStore.user?.uid,
              });
              tour.complete();
              // Timeout is to wait for the current step to finish and then start the welcome tour, otherwise user would get a flickering between two tours.
              setTimeout(() => {
                this.autoTuneTour.start();
              }, 100);
            },
            classes: 'button-primary cursor-pointer no-border',
          },
          {
            text: () => this.t('tutorial.let_us_go'),
            action: () => {
              tour.next();
            },
            classes: `button-primary cursor-pointer ${hasFormerStep ? '' : 'q-ml-auto'}`,
          },
        ].slice(hasFormerStep ? 0 : 1),
      }
    },
    resetAutoTuneTour(tour: Shepherd.Tour) {
      tour.complete();
      this.activeAutoTuneTour = undefined;
      // Timeout is to wait for the current step to finish and then start the welcome tour, otherwise user would get a flickering between two tours.
      setTimeout(() => {
        this.startAutoTune();
      }, 100);
    },
    startAutoTuneTour(
      {tour, item, hasFormerStep, steps}:
      {
        tour: Shepherd.Tour,
        item: AutoTuneFeature,
        hasFormerStep: boolean,
        steps: Record<string, Shepherd.Step.StepOptions>
      }): void {

      if (tour.steps.length === 0) {
        const userStore = useUserStore();

        tour.addSteps([
          this.getAutoTuneWelcomeStep({item, tour, hasFormerStep}),
          ...Object.values(steps)
        ]);

        tour.on('start', () => {
          this.activeAutoTuneTour = tour;
          logEvent(analytics, 'PP_on_boarding_start', {
            feature: 'auto_tune',
            feature_detail: item,
            from: hasFormerStep ? 'auto_tune': 'welcome',
            user_id: userStore.user?.uid,
          });
        });

        tour.on('show', ({ step, tour }: { previous: null | Shepherd.Step, step: Shepherd.Step, tour: Shepherd.Tour; }) => {
          logEvent(analytics, `PP_on_boarding_step_${step.id}`, {
            step: tour.steps.findIndex(item => item.id === step.id),
            text: step.options?.text,
            id: step.id,
            user_id: userStore.user?.uid,
          });
        });

        tour.on('cancel', () => {
          this.activeAutoTuneTour = undefined;
          logEvent(analytics, `PP_on_boarding_cancel_${item}`, {
            feature: 'auto_tune',
            user_id: userStore.user?.uid,
          });
        });

      } else {
        const welcomeStep = tour.getById('feature_welcome');
        welcomeStep?.updateStepOptions(this.getAutoTuneWelcomeStep({item, tour, hasFormerStep}));
      }

      tour.start();
    },
    startTextOptimization(hasFormerStep = false) {
      const autoTuneStore = useAutoTuneStore();
      const userStore = useUserStore();
      const achievementStore = useAchievementStore();

      const TEXT_OPTIMIZER_TUTORIAL: { [key: string]: Shepherd.Step.StepOptions; } = {
        openDropdown: {
          classes: 'tour-optimizer-model',
          text: () => this.t('tutorial.open_selector3'),
          attachTo: {
            element: '.tour-optimizer-model',
            on: 'right',
          },
          id: 'text_open_dropdown',
          beforeShowPromise: async () => {
            autoTuneStore.modalityTab = MODALITY_TABS.TEXT;
            autoTuneStore.draftPrompt.targetModel = '';
            const ele = document.querySelector(
              '.tour-optimizer-model'
            ) as HTMLElement;
            if (ele?.getBoundingClientRect()?.top < 40) {
              const target = getScrollHeight(ele);
              setVerticalScrollPosition(window, -target, 50);
            }
          },
        },
        selectModel: {
          classes: 'tour-optimizer-text-model-dropdown',
          text: () =>
            `<p>${this.t('tutorial.select_text_model', {
              _model: this.t('llama3-70b-instruct'),
            })}</p>`,
          attachTo: {
            element: '.tour-optimizer-text-model-dropdown',
            on: 'right-start',
          },
          id: 'text_select_model',
          beforeShowPromise: async () => {
            autoTuneStore.highlightModel = 'llama3-70b-instruct';
            await nextTick();
          },
        },
        inputPrompt: {
          classes: 'tour-optimizer-input',
          text: () =>
            `<p>${this.t('tutorial.input_prompt_p1', {
              _element:
                '<i class="q-icon notranslate material-icons" aria-hidden="true" role="img" style="font-size: 20px;">send</i>',
            })}</p>` + `<p>${this.t('tutorial.input_prompt_p2')}</p>`,
          attachTo: {
            element: '.tour-optimizer-input',
            on: 'top-start',
          },
          id: 'text_input_prompt',
          buttons: examples.text.map((example) => ({
            text: example,
            action: () => {
              autoTuneStore.draftPrompt.prompt = example;
            },
            classes: 'example-button cursor-pointer',
          })),
          when: {
            show: () => {
              autoTuneStore.draftPrompt.features?.push(TOUR_OPTIMIZE_FEATURES[TOUR_FEATURES.TEXT]);
            },
          },
        },
        optimizeResult: {
          classes: 'tour-optimizer-result',
          text: () =>
            `<p>${this.t('tutorial.optimized_text_prompt_title')}</p>` +
            `<p>${userStore.isRateLimit ? this.t('tutorial.optimized_text_prompt_p1') : this.t('tutorial.optimized_text_prompt_with_credit_p1')}</p>`,
          attachTo: {
            element: '.tour-optimizer-result',
            on: 'right-start',
          },
          id: 'text_optimize_result',
          buttons: [
            {
              text: () => this.t('tutorial.got_it'),
              action: () => {
                this.resetAutoTuneTour(this.textOptimizerTour);
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
          when: {
            show: () => {
              achievementStore.update(TOUR_ACHIEVEMENTS[TOUR_FEATURES.TEXT]);
            },
          },
        },
      };

      this.startAutoTuneTour({
        tour: this.textOptimizerTour,
        item: TOUR_FEATURES.TEXT,
        hasFormerStep,
        steps: TEXT_OPTIMIZER_TUTORIAL
      })
    },
    startImageOptimization(hasFormerStep = false) {
      const autoTuneStore = useAutoTuneStore();
      const achievementStore = useAchievementStore();
      const userStore = useUserStore();

      const IMAGE_OPTIMIZER_TUTORIAL: { [key: string]: Shepherd.Step.StepOptions; } = {
        openDropdown: {
          classes: 'tour-optimizer-model',
          text: () => this.t('tutorial.open_selector4'),
          attachTo: {
            element: '.tour-optimizer-model',
            on: 'right',
          },
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              autoTuneStore.modalityTab = MODALITY_TABS.IMAGE;
              autoTuneStore.draftPrompt.targetModel = '';
              const ele = document.querySelector(
                '.tour-optimizer-model'
              ) as HTMLElement;
              if (ele?.getBoundingClientRect()?.top < 40) {
                const target = getScrollHeight(ele);
                setVerticalScrollPosition(window, -target, 50);
              }
              resolve(true);
            });
          },
          id: 'image_open_dropdown',
        },
        selectModel: {
          classes: 'tour-optimizer-image-model-dropdown',
          text: () => `<p>${this.t('tutorial.select_image_model', { _model: this.t('sdxl') })}<p>`,
          attachTo: {
            element: '.tour-optimizer-image-model-dropdown',
            on: 'right-start',
          },
          id: 'image_select_model',
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              autoTuneStore.highlightModel = 'sdxl';
              const interval = setInterval(() => {
                const element = document.querySelector(
                  '.tour-optimizer-image-model-dropdown'
                );
                if (element) {
                  clearInterval(interval);
                  return resolve(true);
                }
              }, 10);
            });
          },
        },
        inputPrompt: {
          classes: 'tour-optimizer-input',
          text: () =>
            `<p>${this.t('tutorial.input_prompt_p1', {
              _element:
                '<i class="q-icon notranslate material-icons" aria-hidden="true" role="img" style="font-size: 20px;">send</i>',
            })}</p>` + `<p>${this.t('tutorial.input_prompt_p2')}</p>`,
          attachTo: {
            element: '.tour-optimizer-input',
            on: 'top-start',
          },
          id: 'image_input_prompt',
          buttons: examples.image.map((example) => ({
            text: example,
            action: () => {
              autoTuneStore.draftPrompt.prompt = example;
            },
            classes: 'example-button cursor-pointer',
          })),
          when: {
            show: () => {
              autoTuneStore.draftPrompt.features?.push(TOUR_OPTIMIZE_FEATURES[TOUR_FEATURES.IMAGE]);
            },
          },
        },
        optimizeResult: {
          classes: 'tour-optimizer-result',
          text: () =>
            `<p>${this.t('tutorial.optimized_image_prompt_title')}</p>` +
            `<p>${userStore.isRateLimit ? this.t('tutorial.optimized_image_prompt_p1') : this.t('tutorial.optimized_image_prompt_with_credit_p1')}</p>`,
          attachTo: {
            element: '.tour-optimizer-result',
            on: 'right-start',
          },
          id: 'image_optimize_result',
          buttons: [
            {
              text: () => this.t('tutorial.got_it'),
              action: () => {
                this.resetAutoTuneTour(this.imageOptimizerTour);
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
          when: {
            show: () => {
              achievementStore.update(TOUR_ACHIEVEMENTS[TOUR_FEATURES.IMAGE]);
            },
          },
        },
      };

      this.startAutoTuneTour({
        tour: this.imageOptimizerTour,
        item: TOUR_FEATURES.IMAGE,
        hasFormerStep,
        steps: IMAGE_OPTIMIZER_TUTORIAL
      });
    },
    startAddon(hasFormerStep = false) {
      const userStore = useUserStore();
      const achievementStore = useAchievementStore();

      const ADDON_TUTORIAL: { [key: string]: Shepherd.Step.StepOptions; } = {
        openDropdown: {
          classes: 'tour-optimizer-addon-button',
          text: () => this.t('tutorial.addon_open_selector'),
          attachTo: {
            element: '.tour-optimizer-addon-button',
            on: 'bottom-start',
          },
          id: 'addon_open_dropdown',
          buttons: [
            {
              text: () => this.t('tutorial.continue'),
              action: () => {
                // check if dropdown has been triggered or not
                if (!document.querySelector('.tour-optimizer-addon-dropdown-list')) {
                  (document.querySelector('.tour-optimizer-addon-button') as HTMLDivElement)?.click();
                }

                this.addonTour.next();
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              const ele = document.querySelector(
                '.tour-optimizer-addon-button'
              ) as HTMLElement;
              if (ele?.getBoundingClientRect()?.top < 40) {
                const target = getScrollHeight(ele);
                setVerticalScrollPosition(window, -target, 50);
              }
              resolve(true);
            });
          },
        },
        setPreview: {
          classes: 'tour-optimizer-addon-dropdown-preview',
          text: () => `${this.t('tutorial.addon_select_preview')}<img loading="lazy" alt="preview" class="block q-mt-sm full-height full-width" src="/auto_tune_preview.gif">`,
          attachTo: {
            element: '.tour-optimizer-addon-dropdown-preview',
            on: 'right-start',
          },
          id: 'addon_set_preview',
          buttons: [
            {
              text: () => this.t('tutorial.continue'),
              action: () => {
                this.addonTour.next();
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              const interval = setInterval(() => {
                const element = document.querySelector(
                  '.tour-optimizer-addon-dropdown'
                );
                if (element) {
                  clearInterval(interval);
                  return resolve(true);
                }
              }, 10);
            });
          },
        },
        setDiversify: {
          classes: 'tour-optimizer-addon-dropdown-diversify',
          text: () => `${this.t('tutorial.addon_select_diversify')}<img loading="lazy" alt="preview" class="block q-mt-sm full-height full-width" src="/auto_tune_diversify.gif">`,
          attachTo: {
            element: '.tour-optimizer-addon-dropdown-diversify',
            on: 'right-start',
          },
          id: 'addon_set_diversify',
          buttons: [
            {
              text: () => this.t('tutorial.continue'),
              action: () => {
                this.addonTour.next();
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
        },
        setLanguage: {
          classes: 'tour-optimizer-addon-dropdown-language',
          text: () => `${this.t('tutorial.addon_select_language')}<img loading="lazy" alt="preview" class="block q-mt-sm full-height full-width" src="/auto_tune_language.gif">`,
          attachTo: {
            element: '.tour-optimizer-addon-dropdown-language',
            on: 'right-start',
          },
          id: 'addon_set_language',
          buttons: [
            {
              text: () => this.t('tutorial.continue'),
              action: () => {
                this.addonTour.next();
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
        },
        done: {
          classes: 'tour-optimizer-addon-dropdown',
          text: () =>
            `<p>${this.t('tutorial.optimized_result_title')}</p>` +
            `<p>${this.t('tutorial.addon_done')}${!userStore.isRateLimit ? ` ${this.t('tutorial.earned_15_credits')}` : ''}</p>`,
          attachTo: {
            element: '.tour-optimizer-addon-dropdown',
            on: 'right-start',
          },
          id: 'addon_done',
          buttons: [
            {
              text: () => this.t('tutorial.got_it'),
              action: () => {
                (document.querySelector('.tour-optimizer-addon-button') as HTMLDivElement)?.click();
                this.resetAutoTuneTour(this.addonTour);
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
          when: {
            show: () => {
              achievementStore.update(TOUR_ACHIEVEMENTS[TOUR_FEATURES.ADDON]);
            },
          },
        }
      };

      this.startAutoTuneTour({
        tour: this.addonTour,
        item: TOUR_FEATURES.ADDON,
        hasFormerStep,
        steps: ADDON_TUTORIAL
      });
    },
    startVariables(hasFormerStep = false) {
      const autoTuneStore = useAutoTuneStore();
      const achievementStore = useAchievementStore();
      const userStore = useUserStore();

      const getVariableButtons = () => {
        const targetExample = examples.variables.find(item => item.prompt === autoTuneStore.draftPrompt.prompt);
        return targetExample?.variableExamples?.map((item) => ({
          text: `<div>${item}</div>`,
          action: () => {
            autoTuneStore.draftPrompt.previewVariables = {
              [targetExample.variable]: item
            };
          },
          classes: 'example-button cursor-pointer',
        }));
      };

      const VARIABLES_TUTORIAL: { [key: string]: Shepherd.Step.StepOptions; } = {
        inputPrompt: {
          classes: 'tour-optimizer-input',
          text: () => this.t('tutorial.variables_input_prompt_p2', { VAR_0: '[VAR] or {VAR}' }),
          attachTo: {
            element: '.tour-optimizer-input',
            on: 'bottom-start',
          },
          id: 'variables_input_prompt',
          buttons: examples.variables.map((example) => ({
            text: `<div class="flex items-center"><img class="q-mr-xs" style="height: 1rem" src="${supportedModels.find(item => item.name === example.model)?.icon?.replace('img:', '')}" />${example.prompt}</div>`,
            action: () => {
              autoTuneStore.draftPrompt.prompt = example.prompt;
              autoTuneStore.draftPrompt.targetModel = example.model as any;
              this.variablesTour.next();
            },
            classes: 'example-button cursor-pointer',
          })),
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              const ele = document.querySelector(
                '.tour-optimizer-input'
              ) as HTMLElement;
              if (ele?.getBoundingClientRect()?.top < 40) {
                const target = getScrollHeight(ele);
                setVerticalScrollPosition(window, -target, 50);
              }
              resolve(true);
            });
          },
          when: {
            show: () => {
              autoTuneStore.draftPrompt.features?.push(TOUR_OPTIMIZE_FEATURES[TOUR_FEATURES.VARIABLES]);
            },
          },
        },
        openVariable: {
          classes: 'tour-optimizer-variables',
          text: () => this.t('tutorial.variables_open_popup'),
          attachTo: {
            element: '.tour-optimizer-variables',
            on: 'bottom-start',
          },
          id: 'variables_open_popup',
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              const interval = setInterval(() => {
                const element = document.querySelector(
                  '.tour-optimizer-variables'
                );
                if (element) {
                  clearInterval(interval);
                  return resolve(true);
                }
              }, 10);
            });
          },
        },
        inputValue: {
          classes: 'tour-optimizer-variables-value',
          text: () => this.t('tutorial.variables_input_value'),
          attachTo: {
            element: '.tour-optimizer-variables-value',
            on: 'right-start',
          },
          id: 'variables_input_value',
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              const interval = setInterval(() => {
                const element = document.querySelector(
                  '.tour-optimizer-variables-value'
                );
                if (element) {
                  clearInterval(interval);
                  return resolve(true);
                }
              }, 10);
            });
          },
          when: {
            show: () => {
              const currentStep = this.variablesTour.getCurrentStep();
              currentStep?.updateStepOptions({
                ...currentStep,
                buttons: getVariableButtons(),
              });
            }
          }
        },
        sendPrompt: {
          classes: 'tour-optimizer-input',
          text: () => this.t('tutorial.variables_prompt_send', { _element: '<i class="q-icon notranslate material-icons" aria-hidden="true" role="img" style="font-size: 20px;">send</i>' }),
          attachTo: {
            element: '.tour-optimizer-input',
            on: 'top-end',
          },
          id: 'variables_send_prompt',
        },
        optimizeResult: {
          classes: 'tour-optimizer-result',
          text: () =>
            `<p>${this.t('tutorial.optimized_result_title')}</p>` +
            `<p>${userStore.isRateLimit ? this.t('tutorial.variables_optimized_result_p1') : this.t('tutorial.variables_optimized_result_with_credit_p1')}</p>`,
          attachTo: {
            element: '.tour-optimizer-result',
            on: 'right-start',
          },
          id: 'variables_optimize_result',
          buttons: [
            {
              text: () => this.t('tutorial.got_it'),
              action: () => {
                this.resetAutoTuneTour(this.variablesTour);
              },
              classes: 'button-primary cursor-pointer',
            },
          ],
          when: {
            show: () => {
              achievementStore.update(TOUR_ACHIEVEMENTS[TOUR_FEATURES.VARIABLES]);
            },
          },
        },
      };

      this.startAutoTuneTour({
        tour: this.variablesTour,
        item: TOUR_FEATURES.VARIABLES,
        hasFormerStep,
        steps: VARIABLES_TUTORIAL
      });
    },

    startAutoTune() {
      const autoTuneStore = useAutoTuneStore();
      const achievementStore = useAchievementStore();
      const userStore = useUserStore();

      const getWelcomeButtons = () => {
        return Object.keys(TOUR_ACHIEVEMENTS).map((item) => ({
          text: () =>
            `<span title="${autoTuneStore.limitation[item] ? this.t('tutorial.quota_error') : ''}">` +
            `<i class="q-icon q-mr-xs notranslate material-icons material-symbols-outlined aria-hidden="true" role="presentation" style="font-size: 1rem">${achievementStore.getAchievementById(TOUR_ACHIEVEMENTS[item])?.achieved ? 'check_box' : 'check_box_outline_blank'}</i>` +
            `${this.t('tutorial.task_' + item)}</span>` +
            `<span>${achievementStore.getAchievementById(TOUR_ACHIEVEMENTS[item])?.achieved
              ? this.t('tutorial.task_complete')
              : userStore.isRateLimit ? this.t('tutorial.task_pending_v1') : this.t('tutorial.task_pending')
            }</span>`,
          action: () => {
            if (autoTuneStore.limitation[item]) return;

            this.autoTuneTour.complete();
            this.activeAutoTuneTour = undefined;
            // start the feature tour accordingly
            // Timeout is to wait for the welcome step to finish and then start the feature tour, otherwise user would get a flickering between two tours.
            setTimeout(() => {
              switch (item) {
                case TOUR_FEATURES.TEXT:
                  this.startTextOptimization(true);
                  break;
                case TOUR_FEATURES.IMAGE:
                  this.startImageOptimization(true);
                  break;
                case TOUR_FEATURES.ADDON:
                  this.startAddon(true);
                  break;
                case TOUR_FEATURES.VARIABLES:
                  this.startVariables(true);
                  break;
                default:
                  break;
              }
            }, 100);
          },
          classes: 'button-primary cursor-pointer list' + (achievementStore.getAchievementById(TOUR_ACHIEVEMENTS[item])?.achieved ? ' achieved' : (item === TOUR_FEATURES.TEXT ? ' text-primary' : '')) + (autoTuneStore.limitation[item] ? ' disabled' : ''),
        }));
      };

      if (this.autoTuneTour.steps.length === 0) {
        this.autoTuneTour.addStep({
          text: () =>
            `<h4>${this.t('tutorial.welcome_title')}</h4>` +
            `<p>${this.t('tutorial.welcome_p1')}</p>` +
            `<p>${this.t('tutorial.welcome_p2')}</p>`,
          classes: 'tour-welcome',
          id: 'welcome',
          cancelIcon: {
            enabled: true,
          },
          buttons: getWelcomeButtons(),
          when: {
            show: async () => {
              await autoTuneStore.checkTutorialQuota();
            }
          }
        });

        this.autoTuneTour.on('start', () => {
          logEvent(analytics, 'PP_on_boarding_start', {
            feature: 'auto_tune',
            user_id: useUserStore().user?.uid,
          });
          this.activeAutoTuneTour = this.autoTuneTour;
          // Won't show tutorial again as long as it's shown once
          const userStore = useUserStore();
          userStore.settings.showAutoTune = false;
          userStore.savePreferences();
        });

        this.autoTuneTour.on('show', ({ step, tour }: { previous: null | Shepherd.Step, step: Shepherd.Step, tour: Shepherd.Tour; }) => {
          logEvent(analytics, `PP_on_boarding_step_${step.id}`, {
            step: tour.steps.findIndex(item => item.id === step.id),
            text: step.options?.text,
            id: step.id,
            user_id: userStore.user?.uid,
          });
        });

        this.autoTuneTour.on('cancel', () => {
          const id = this.autoTuneTour.getCurrentStep()?.id;
          if (id) {
            logEvent(analytics, `PP_on_boarding_cancel_${id}`, {
              feature: 'auto_tune',
              user_id: useUserStore().user?.uid,
            });
          }
          this.activeAutoTuneTour = undefined;
          if (!this.cancelTour.isActive()) {
            setTimeout(() => {
              this.startCancelTour();
            }, 100);
          }
        });
      }

      this.autoTuneTour.start();
    },

    startInteractive() {
      const userStore = useUserStore();
      const interactiveStore = useInteractiveStore();

      if (this.interactiveTour.steps.length === 0) {
        interactiveStore.initialMessageForTutorial();
        this.interactiveTour.addSteps([{
          classes: 'tour-interactive-assistant',
          text: () =>
            `<p>${this.t('tutorial.revised_interactive_assistant_chat_p1', { _target: this.t('interactive.assistant') })}</p>` +
            `<p>${this.t('tutorial.revised_interactive_assistant_chat_p2', { _target: this.t('interactive.assistant') })}</p>`,
          attachTo: {
            element: '.tour-interactive-assistant',
            on: 'right',
          },
          id: 'interactive_assistant_input',
          buttons: [{
            text: () => this.t('tutorial.next'),
            action: () => {
              this.interactiveTour.next();
            },
            classes: 'button-primary self-end cursor-pointer q-mt-lg',
          }],
          floatingUIOptions: {
            middleware: [size({
              apply({ elements, rects }) {
                Object.assign(elements.floating.style, {
                  maxWidth: `${window.innerWidth - rects.reference.width - rects.reference.x - 20}px`,
                });
              },
            })],
          },
        },
        {
          classes: 'tour-interactive-optimizer',
          text: () =>
            `<p>${this.t('tutorial.revised_interactive_optimizer_chat', { _target: this.t('interactive.optimizer') })}</p>`,
          attachTo: {
            element: '.tour-interactive-optimizer',
            on: 'left',
          },
          id: 'interactive_optimizer_input',
          buttons: [{
            text: () => this.t('tutorial.next'),
            action: () => {
              this.interactiveTour.next();
              interactiveStore.setMessageForTutorial(ChatType.OPTIMIZER);
            },
            classes: 'button-primary self-end cursor-pointer q-mt-lg',
          }],
        },
        {
          classes: 'tour-interactive-optimized-prompt',
          text: () =>
            `<p>${this.t('tutorial.revised_interactive_optimizer_result_p1')}</p>` +
            `<p>${this.t('tutorial.revised_interactive_optimizer_result_p2', { _target: this.t('interactive.optimizer'), _button: this.t('interactive.view_in_assistant_btn') })}</p>`,
          attachTo: {
            element: '.tour-interactive-optimized-prompt',
            on: 'left',
          },
          id: 'interactive_optimizer_result',
          buttons: [{
            text: () => this.t('tutorial.next'),
            action: () => {
              this.interactiveTour.next();
              interactiveStore.setMessageForTutorial(ChatType.ASSISTANT);
            },
            classes: 'button-primary self-end cursor-pointer',
          }],
          beforeShowPromise: () => {
            return new Promise((resolve) => {
              const interval = setInterval(() => {
                const element = document.querySelector(
                  '.tour-interactive-optimized-prompt'
                );
                if (element) {
                  clearInterval(interval);
                  return resolve(true);
                }
              }, 10);
            });
          },
          floatingUIOptions: {
            middleware: [shift()],
          },
        },
        {
          classes: 'tour-interactive-assistant',
          text: () => `<p>${this.t('tutorial.revised_interactive_assistant_result', { _target: this.t('interactive.assistant') })}</p>`,
          attachTo: {
            element: '.tour-interactive-assistant',
            on: 'right',
          },
          id: 'interactive_assistant_result',
          buttons: [{
            text: () => this.t('tutorial.next'),
            action: () => {
              this.interactiveTour.next();
            },
            classes: 'button-primary self-end cursor-pointer',
          }],
          floatingUIOptions: {
            middleware: [size({
              apply({ elements, rects }) {
                Object.assign(elements.floating.style, {
                  maxWidth: `${window.innerWidth - rects.reference.width - rects.reference.x - 20}px`,
                });
              },
            })],
          },
        },
        {
          classes: 'tour-interactive-new-chat',
          text: () =>
            `<p>${this.t('tutorial.revised_interactive_new_chat_p1')}</p>` +
            `<p>${this.t('tutorial.revised_interactive_new_chat_p2', { _target: this.t('interactive.name'), _button: this.t('interactive.new_session') })}</p>`,
          attachTo: {
            element: '.tour-interactive-new-chat',
            on: 'right-start',
          },
          id: 'interactive_new_chat',
          buttons: [
            {
              text: () => this.t('tutorial.try_it_out'),
              action: () => {
                this.interactiveTour.complete();
                interactiveStore.resetMessages();
              },
              classes: 'button-primary self-end cursor-pointer',
            },
          ]
        },
        ]);

        this.interactiveTour.on('start', () => {
          logEvent(analytics, 'PP_on_boarding_start', {
            feature: 'interactive',
            user_id: userStore.user?.uid,
          });
        });

        this.interactiveTour.on('show', ({ step, tour }: { previous: null | Shepherd.Step, step: Shepherd.Step, tour: Shepherd.Tour; }) => {
          logEvent(analytics, `PP_on_boarding_step_${step.id}`, {
            step: tour.steps.findIndex(item => item.id === step.id),
            text: step.options?.text,
            id: step.id,
            user_id: userStore.user?.uid,
          });
        });

        this.interactiveTour.on('cancel', () => {
          const id = this.interactiveTour.getCurrentStep()?.id;
          if (id) {
            logEvent(analytics, `PP_on_boarding_cancel_${id}`, {
              feature: 'interactive',
              user_id: userStore.user?.uid,
            });
          }

          if (!this.cancelTour.isActive()) {
            setTimeout(() => {
              this.startCancelTour();
            }, 100)
          }
        });
      }

      this.interactiveTour.start();
    },

    startCancelTour() {
      if (this.hasShownCancelTour) {
        return;
      }

      if (
        typeof this.router.currentRoute.value.name === 'string'
        && !this.displayTourIcons.includes(this.router.currentRoute.value.name)
      ) {
        return;
      }

      const userStore = useUserStore();

      if (this.cancelTour.steps.length === 0) {
        this.cancelTour.addStep({
          text: () => {
            return `${this.t('tutorial.start_tutorial')}<i class="q-icon q-ml-xs notranslate material-icons cursor-pointer tour-start shepherd-enabled shepherd-target" aria-hidden="true" role="presentation" style="font-size: 24px;">school</i>`;
          },
          attachTo: {
            element: '.tour-start',
            on: 'bottom-start',
          },
          id: 'header_start',
          buttons: [{
            text: () => {
              return this.t('tutorial.got_it');
            },
            action: () => {
              this.cancelTour.complete();
            },
            classes: 'button-primary self-end cursor-pointer'
          }],
        });

        this.cancelTour.on('start', () => {
          userStore.highlightTourIcon = true;
          logEvent(analytics, 'PP_on_boarding_cancel', {
            user_id: userStore.user?.uid,
          });
        });

        this.cancelTour.on('complete', () => {
          userStore.highlightTourIcon = false;
          this.hasShownCancelTour = true;
        });
      }

      this.cancelTour.start();
    },

    openTour(routeName: 'interactive' | 'prompts') {
      switch (routeName) {
        case 'interactive':
          this.startInteractive();
          break;
        case 'prompts':
          this.startAutoTune();
          break;
      }
    },

    async startAssistantTour() {
      this.cancelInteractiveButtonTour();
      const userStore = useUserStore();
      const {t} = this;
      const btns = document.querySelectorAll('.send-to-optimize-button');
      if (btns.length <= 0 || this.interactiveTour.isActive() || userStore.settings.hasShownAssistantTour) return;

      const target = btns[btns.length - 1] as HTMLDivElement;
      this.assistantTour= new Shepherd.Tour({
        useModalOverlay: false,
      });

      this.assistantTour.addStep({
        id: 'send-to-optimize',
        text: () => {
          return `${ t('interactive.click_optimize', {_btn: t('interactive.optimize_btn')}) }<img class="full-width" src="/_interactive/send-to-optimizer.gif" alt="Animation that introduces the 'Optimize' button."/>`
        },
        arrow: false,
        attachTo: {
          element: target,
          on: 'bottom-start',
        },
        buttons: [
          {
            text: t('tutorial.got_it'),
            action: () => {
              this.assistantTour?.complete();
              userStore.settings.hasShownAssistantTour = true;
              userStore.savePreferences();
              logEvent(analytics, 'PP_interactive_send_to_optimizer_tutorial');
            },
            classes: 'button-primary cursor-pointer self-end'
          },
        ],
      });

      this.assistantTour.on('show', () => {
        userStore.settings.hasShownAssistantTour = true;
        userStore.savePreferences();
      });
      this.assistantTour.start();

      setTimeout(() => {
        this.assistantTour?.complete();
      }, 20000);
    },

    async startOptimizerTour() {
      this.cancelInteractiveButtonTour();
      const userStore = useUserStore();
      const {t} = this;

      const btns = document.querySelectorAll('.send-to-assistant-button');
      if (btns.length <= 0 || this.interactiveTour.isActive() || userStore.settings.hasShownOptimizerTour) return;

      const target = btns[btns.length - 1] as HTMLDivElement;
      this.optimizerTour = new Shepherd.Tour({
        useModalOverlay: false,
      });

      this.optimizerTour.addStep({
        id: 'send-to-assistant',
        text: () => {
          return `${ t('interactive.click_view_in_assistant', {_btn: t('interactive.view_in_assistant_btn')}) }<img class="full-width" src="/_interactive/send-to-assistant.gif" alt="Animation that introduces the 'Send to Assistant' button."/>`
        },
        arrow: false,
        attachTo: {
          element: target,
          on: 'bottom-start',
        },
        buttons: [
          {
            text: t('tutorial.got_it'),
            action: () => {
              this.optimizerTour?.complete();
              userStore.settings.hasShownOptimizerTour = true;
              userStore.savePreferences();
              logEvent(analytics, 'PP_interactive_send_to_assistant_tutorial');
            },
            classes: 'button-primary cursor-pointer self-end'
          },
        ],
      });

      this.optimizerTour.on('show', () => {
        userStore.settings.hasShownOptimizerTour = true;
        userStore.savePreferences();
      });

      this.optimizerTour.start();
      setTimeout(() => {
        this.optimizerTour?.complete();
      }, 20000);
    },

    cancelInteractiveButtonTour() {
      this.assistantTour?.hide();
      this.optimizerTour?.hide();
    }
  }
});
