import { ref } from 'vue';
import { defineStore } from 'pinia';
import { logEvent } from 'firebase/analytics';
import { collection, getDocs, doc, limit, orderBy, query, where } from 'firebase/firestore';
import { Notify } from 'quasar';
import { uniq } from 'lodash-es';

import { apiBaseUrl, db, groupedFunctions, analytics } from 'src/fb';
import { MODALITY_TABS, supportedModels } from 'src/constants';
import { TOUR_OPTIMIZE_FEATURES } from 'src/constants';
import type { PromptRequestType, PromptUIType, PromptResponseType } from 'src/types/prompts';

import { useUserStore } from './user-store';
import { useAchievementStore } from './achievement';
import { useEnsembleStore } from './ensemble';
import { STREAM_STATUS } from 'src/utils/stream';
import { useArenaStore } from './arena';

interface DurationStatistic {
  /**
   * Average execution time in seconds of corresponding model
   */
  duration: number;
  /**
   * Number of model executions
   */
  count: number;
}

const defaultDurationStatistics = {
  default: { duration: 20, count: 0 },
  dalle: { duration: 20, count: 0 },
  sd: { duration: 20, count: 0 },
  chatgpt: { duration: 20, count: 0 },
  midjourney: { duration: 20, count: 0 },
  lexica: { duration: 20, count: 0 },
  kandinsky: { duration: 20, count: 0 },
  'gpt-4': { duration: 20, count: 0 },
  claude: { duration: 20, count: 0 },
  cogenerate: { duration: 20, count: 0 },
  'stablelm-tuned-alpha-7b': { duration: 20, count: 0 },
  jinachat: { duration: 20, count: 0 },
  'claude-2': { duration: 20, count: 0 },
  'ernie-bot-turbo': { duration: 20, count: 0 },
  'ernie-bot-4': { duration: 20, count: 0 },
  'chatglm-turbo': { duration: 20, count: 0 },
  'llama-2-70b-chat': { duration: 60, count: 0 },
};

interface State {
  /**
   * Current prompt text
   */
  draftPrompt: PromptRequestType & {
    isBusy?: boolean;
  };
  modalityTab?: MODALITY_TABS;
  highlightModel?: string;
  lastPromptSettings: PromptRequestType;
  /**
   * Optimized prompts history list
   */
  prompts: PromptUIType[];
  /**
   * Exporting history prompts to an excel
   */
  exporting: boolean;
  limitation: { [key: string]: boolean };
  previewControllers: { [key: string]: AbortController };
  durationStatistics: Record<keyof typeof defaultDurationStatistics, DurationStatistic>;
  /**
   * Bulk prompts from uploaded CSV
   */
  bulkPromptsFromCSV: PromptRequestType[];
}

export const examples = {
  text: [
    'Resume template for an accountant with 10 years experience',
    'Video script for a Youtube video about investment strategies',
    'Python code to perform sentiment analysis on sentences',
    '用中文概括《了不起的盖茨比》',
  ],
  image: [
    'A large grey teddy bear a in cozy bedroom',
    'Night view of a Japanese street with neon lights',
    'An astronaut standing on the surface of Mars',
    'A cheerful dog running through grassy field',
  ],
  variables: [
    {
      model: 'dalle-3',
      prompt: 'A cute {animal} flying in the sky.',
      variableExamples: ['cat', 'dog', 'bird', 'butterfly'],
      variable: 'animal'
    },
    {
      model: 'dalle-3',
      prompt: 'Create an artwork of {city}, dawn.',
      variableExamples: ['New York', 'Paris', 'London', 'Tokyo'],
      variable: 'city'
    },
    {
      model: 'gpt-4-turbo',
      prompt: 'Summarize {historical_era}\'s impact on today’s life.',
      variableExamples: ['the Renaissance', 'the Industrial Revolution', 'the Middle Ages', 'the Age of Enlightenment'],
      variable: 'historical_era'
    },
    {
      model: 'gpt-4-turbo',
      prompt: 'Script for a Youtube video about {content}.',
      variableExamples: ['investment strategies', 'the history of the internet', 'the life of Albert Einstein', 'the life of Marie Curie'],
      variable: 'content'
    }
  ],
};

const TOUR_QUOTA = 5;

export const useAutoTuneStore = defineStore('auto-tune', {
  state: (): State => ({
    draftPrompt: {
      prompt: '',
      imagePrompt: '',
      previewVariables: {},
      targetModel: '',
      features: [],
    },
    modalityTab: MODALITY_TABS.TEXT,
    highlightModel: '',
    lastPromptSettings: {
      prompt: '',
      features: ['preview'],
      iterations: 1,
      resultCount: 2,
      targetModel: 'gpt-3.5-turbo',
      uiLanguage: 'en',
      targetLanguage: 'en',
    },
    prompts: [],
    exporting: false,
    limitation: {},
    previewControllers: {},
    durationStatistics: defaultDurationStatistics,
    bulkPromptsFromCSV: [],
  }),

  persist: {
    paths: [
      'draftPrompt',
      'modalityTab',
      'highlightModel',
      'lastPromptSettings',
      'prompts',
      'exporting',
      'limitation',
      'durationStatistics',
    ]
  },

  getters: {
    duration: (state) => {
      return (modelName: string) => {
        return state.durationStatistics[modelName as keyof typeof defaultDurationStatistics]?.duration || 20;
      }
    }
  },

  actions: {
    setLastPromptSettings(settings: PromptRequestType) {
      Object.assign(this.lastPromptSettings, settings);
    },

    initDraftPrompt(model?: string) {
      this.draftPrompt = {
        prompt: '',
        imagePrompt: '',
        previewVariables: {},
        targetModel: (model || this.lastPromptSettings.targetModel) as PromptRequestType['targetModel'],
        features: this.lastPromptSettings.features?.concat() || [],
        iterations: this.lastPromptSettings.iterations,
        resultCount: this.lastPromptSettings.resultCount,
        uiLanguage: this.lastPromptSettings.uiLanguage,
        targetLanguage: this.lastPromptSettings.targetLanguage,
      };
    },

    async exportXlsx() {
      const userStore = useUserStore();

      if (this.exporting) {
        return;
      }

      this.exporting = true;
      try {
        const idToken = await userStore.user?.getIdToken();

        const url = `${apiBaseUrl}/autoTune/exportMyPromptsAsXlsx?_idToken=${idToken}&_rand=${Math.random()}`;
        // const url = `http://127.0.0.1:5001/prompt-ops/us-central1/api/exportMyPromptsAsXlsx?_idToken=${idToken}&_rand=${Math.random()}`;

        window.open(url, '_blank');
      } finally {
        this.exporting = false;
      }
    },

    async generatePreview(
      prompt: PromptUIType,
      beforeOrAfter: 'before' | 'after',
      signal?: AbortSignal,
      stream = false,
      id = ''
    ) {
      let currentTimeout = 0;
      const timeoutStat = setInterval(() => {
        currentTimeout += 1;
      }, 100);
      const isBefore = beforeOrAfter === 'before';

      if (isBefore) {
        prompt.previewResults = {
          ...prompt.previewResults,
          before: '',
          beforeStream: [],
          beforeTimeElapsed: 0,
          beforeLoading: true,
          beforeStreamStatus: STREAM_STATUS.RUNNING,
        };
      } else {
        prompt.previewResults = {
          ...prompt.previewResults,
          after: '',
          afterStream: [],
          afterTimeElapsed: 0,
          afterLoading: true,
          afterStreamStatus: STREAM_STATUS.RUNNING,
        };
      }

      try {
        if (!prompt.previewResults) return;

        const response = await groupedFunctions.request('autoTune', 'po_generatePreview', {
          body: JSON.stringify({
            id: prompt.id || id,
            prompt: isBefore ? prompt.prompt : prompt.promptOptimized,
            beforeOrAfter,
            stream,
          }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          signal
        });

        if (stream) {
          for await (const event of response as unknown as AsyncIterable<any>) {
            if (isBefore && prompt.previewResults.beforeStream) {
              prompt.previewResults.beforeStream.push(event.data);
              prompt.previewResults.beforeTimeElapsed = currentTimeout / 10;
            } else if (prompt.previewResults.afterStream) {
              prompt.previewResults.afterStream.push(event.data);
              prompt.previewResults.afterTimeElapsed = currentTimeout / 10;
            }
          }

          if (isBefore && prompt.previewResults.beforeStream) {
            prompt.previewResults.beforeStreamStatus = STREAM_STATUS.DONE;
            prompt.previewResults.before = prompt.previewResults.beforeStream.join('');
          } else if (prompt.previewResults.afterStream) {
            prompt.previewResults.afterStreamStatus = STREAM_STATUS.DONE;
            prompt.previewResults.after = prompt.previewResults.afterStream.join('');
          }
        } else {
          const data = (response as any).data;
          if (isBefore) {
            prompt.previewResults.before = data;
            prompt.previewResults.beforeTimeElapsed = currentTimeout / 10;
            prompt.previewResults.beforeStreamStatus = STREAM_STATUS.DONE;
          } else {
            prompt.previewResults.after = data;
            prompt.previewResults.afterTimeElapsed = currentTimeout / 10;
            prompt.previewResults.afterStreamStatus = STREAM_STATUS.DONE;
          }
        }
      } catch (err) {
        if (isBefore) {
          prompt.previewResults.before = '';
          prompt.previewResults.beforeStreamStatus = STREAM_STATUS.ERROR;
        } else {
          prompt.previewResults.after = '';
          prompt.previewResults.afterStreamStatus = STREAM_STATUS.ERROR;
        }

        this.handleError(err);
      } finally {
        if (isBefore) {
          prompt.previewResults.beforeLoading = false;
        } else {
          prompt.previewResults.afterLoading = false;
        }
        clearInterval(timeoutStat);
      }
    },

    async optimizePrompt(
      prompt: PromptUIType,
      tutorial?: boolean
    ) {
      const userStore = useUserStore();
      const achievementStore = useAchievementStore();
      const ensembleStore = useEnsembleStore();

      const pendingObj = ref<PromptUIType>({
        prompt: prompt.prompt,
        targetModel: prompt.targetModel,
        features: uniq(prompt.features?.concat(['no_spam', 'variable_subs'])),
        iterations: prompt.iterations,
        uiLanguage: prompt.uiLanguage,
        resultCount: prompt.resultCount,
        error: '',
        progress: 0,
        isBusy: true,
        isFinished: false,
        previewSettings:
          userStore.previewSettings[prompt.targetModel] ||
          ensembleStore.modelSettings[prompt.targetModel],
        previewVariables: prompt.previewVariables,
        targetLanguage: prompt.targetLanguage || 'en',
        previewResults: {
          before: '',
          beforeStream: [],
          beforeTimeElapsed: 0,
          beforeLoading: true,
          after: '',
          afterStream: [],
          afterTimeElapsed: 0,
          afterLoading: true,
          type: '',
        },
        explain: '',
        promptOptimized: '',
        language: 'en',
        id: '',
        createdAt: '',
        userId: '',
        credits: 0,
        results: [],
        timeElapsed: 0,
        refinement: {},
      });

      this.setLastPromptSettings({
        ...prompt,
        features: prompt.features?.filter((item) => !Object.values(TOUR_OPTIMIZE_FEATURES).includes(item))
      });

      const timerProgress = setInterval(() => {
        if (pendingObj.value.progress && pendingObj.value.progress <= 1) {
          pendingObj.value.progress += 1 / Math.max(20, this.duration(pendingObj.value.targetModel));
        } else {
          clearInterval(timerProgress);
        }
      }, 1000);

      let currentTimeout = 0;

      const timeoutStat = setInterval(() => {
        currentTimeout += 1;
      }, 1000);

      this.prompts.unshift(pendingObj.value);
      const reqPayload = {
        ...pendingObj.value,
        imagePrompt: prompt.imagePrompt,
      };

      try {
        const { data } = await groupedFunctions.call<PromptResponseType>('autoTune', 'getPrompt', reqPayload);

        Object.assign(pendingObj.value, data);
        pendingObj.value.isBusy = false;
        pendingObj.value.isFinished = true;
        pendingObj.value.timeElapsed = currentTimeout;

        this.updateDuration(pendingObj.value.targetModel, currentTimeout);

        logEvent(analytics, 'PP_get_prompt', {
          stage: 'success',
          iterations: pendingObj.value.iterations,
          targetModel: pendingObj.value.targetModel,
          features: pendingObj.value.features,
          imagePrompt: !!pendingObj.value.imagePrompt,
          resultCount: pendingObj.value.resultCount,
        });

        if (pendingObj.value.features?.includes('preview')) {
          const model = supportedModels.find(
            (item) => item.name === prompt.targetModel
          );
          const isStreaming = model?.stream;
          if (!this.previewControllers[pendingObj.value.id]) {
            this.previewControllers[pendingObj.value.id] = new AbortController();
          }

          if (pendingObj.value.results && pendingObj.value.results?.length > 0) {
            this.generatePreview(
              pendingObj.value.results[0] as PromptUIType,
              'after',
              this.previewControllers[pendingObj.value.id]?.signal,
              isStreaming,
              pendingObj.value.id
            );
          }
        }

        // unlock achievements
        if (!tutorial) {
          achievementStore.update('first-optimize');
        }

        achievementStore.update('10x-optimize');

        if (pendingObj.value.targetModel === 'gpt-3.5-turbo') {
          achievementStore.update('chatgpt-optimize');
        }
        if (pendingObj.value.targetModel === 'gpt-4') {
          achievementStore.update('gpt4-optimize');
        }
        if (pendingObj.value.targetModel === 'kandinsky') {
          achievementStore.update('kandinsky-optimize');
        }
        if (pendingObj.value.targetModel === 'dalle') {
          achievementStore.update('dalle-optimize');
        }
        if (pendingObj.value.targetModel === 'sd') {
          achievementStore.update('sd-optimize');
        }
        if (pendingObj.value.targetModel === 'midjourney') {
          achievementStore.update('midjourney-optimize');
        }
        if (pendingObj.value.targetModel === 'claude') {
          achievementStore.update('claude-optimize');
        }
        if (pendingObj.value.features?.includes('preview')) {
          achievementStore.update('enabled-preview');
        }
        if (pendingObj.value.targetLanguage && pendingObj.value.targetLanguage !== 'en') {
          achievementStore.update('enabled-multilang');
        }
        userStore.submitUserConversion();
      } catch (error) {
        if (error instanceof Error) {
          pendingObj.value.error = error.message;
        } else {
          pendingObj.value.error = 'Unknown error';
        }

        logEvent(analytics, 'PP_get_prompt', {
          stage: 'failure',
          iterations: pendingObj.value.iterations,
          targetModel: pendingObj.value.targetModel,
          features: pendingObj.value.features,
          imagePrompt: !!pendingObj.value.imagePrompt,
        });

        this.handleError(error);
      } finally {
        pendingObj.value.progress = 1;
        pendingObj.value.isBusy = false;
        pendingObj.value.features = pendingObj.value.features?.filter(f => !Object.values(TOUR_OPTIMIZE_FEATURES).includes(f));
        userStore.loadCredits();
        clearInterval(timerProgress);
        clearInterval(timeoutStat);
      };
    },

    async loadPrompts() {
      const userStore = useUserStore();

      if (!userStore.user) {
        return;
      }

      const promptsRef = collection(db, 'users', userStore.user.uid, 'prompts');
      const q = query(promptsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);

      const threads: PromptUIType[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as PromptUIType;
        threads.push({
          ...data,
          id: doc.id,
          isBusy: false,
          timeElapsed: 0,
        });
      });

      type RawPromptUI = PromptUIType & {
        createdAt: { seconds: number; nanoseconds: number }
      };

      return threads.sort((a, b) => {
        return (b as RawPromptUI).createdAt.seconds - (a as RawPromptUI).createdAt.seconds;
      });
    },

    async deletePrompt(thread: PromptUIType) {
      thread.isBusy = true;
      try {
        await groupedFunctions.call('autoTune', 'deletePromptById', { id: thread.id })
        if (thread.id) {
          this.prompts.splice(
            this.prompts.findIndex((t) => t.id === thread.id),
            1
          );
        }

        Notify.create({
          message: this.t('delete_decision_success'),
          color: 'positive',
          icon: 'check',
        });
      } catch(error) {
        this.handleError(error, this.t('delete_decision_error'));
      } finally {
        thread.isBusy = false;
      }
    },

    async checkTutorialQuota() {
      const userStore = useUserStore();

      const targetDate = Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      );

      await Promise.all(Object.keys(TOUR_OPTIMIZE_FEATURES).map(async (key) => {
        if (!userStore.user) {
          return;
        }

        const collectionRef = collection(doc(db, 'credits', userStore.user.uid), 'freeFeatureRecord');
        const q = query(
          collectionRef,
          where('feature', '==', TOUR_OPTIMIZE_FEATURES[key]),
          limit(TOUR_QUOTA),
          orderBy('createdAt', 'desc'),
        );
        const results = await getDocs(q);
        if (results.size < TOUR_QUOTA) {
          this.limitation[key] = false;
          return;
        } else {
          this.limitation[key] = true;
        }
        results.forEach((doc) => {
          if (new Date(doc.data().createdAt).getTime() < targetDate) {
            this.limitation[key] = false;
            return;
          }
        });
      }));
    },

    updateDuration(model: string, duration: number) {
      const modelName = model as keyof typeof defaultDurationStatistics;
      const current = this.durationStatistics[modelName];
      if (!current) return;

      const count = current.count + 1;
      const newDuration = (current.duration * current.count + duration) / count;
      this.durationStatistics[modelName] = { duration: newDuration, count };
    },

    abortRequest(
      prompt: PromptUIType,
      controller: AbortController
    ) {
      const { t } = this;
      const userStore = useUserStore();
      const ensembleStore = useEnsembleStore();

      prompt.isBusy = false;
      userStore.loadCredits();
      logEvent(analytics, 'PP_arena_timeout', {
        stage: 'error',
        targetModel: prompt.targetModel,
        prompt: prompt.promptOptimized,
      });
      const targetModel = ensembleStore.ensembleModels.find(
        (item) => item._id === prompt.targetModel
      );
      prompt.error = t('arena_timeout', {
        model: targetModel?.name || t(prompt.targetModel),
      });
      Notify.create({
        message: t('arena_timeout', {
          model: targetModel?.name || t(prompt.targetModel),
        }),
        caption: t('arena_timeout_description'),
        color: 'negative',
        icon: 'error',
      });
      controller.abort();
    },

    async previewPrompt(
      prompt: PromptUIType,
      features: string[] = ['template_run']
    ) {
      const userStore = useUserStore();
      const ensembleStore = useEnsembleStore();

      const model = supportedModels.find(
        (item) => item.name === prompt.targetModel
      );

      const isStreaming = model?.stream;

      prompt.isBusy = true;
      const arenaStore = useArenaStore();

      if (!prompt.previewResults) {
        prompt.previewResults = {
          before: '',
          beforeStream: [],
          beforeTimeElapsed: 0,
          beforeLoading: true,
          after: '',
          afterStream: [],
          afterTimeElapsed: 0,
          afterLoading: true,
          type: 'text',
        };
      }

      prompt.previewResults.after = '';
      let defaultLanguage = model?.language === 'en' ? 'en' : userStore.language;
      defaultLanguage = defaultLanguage === 'en-US' ? 'en' : defaultLanguage;

      const promptRequest: PromptRequestType = {
        prompt: prompt.promptOptimized || prompt.prompt,
        targetModel: prompt.targetModel,
        features: features,
        previewVariables: {},
        targetLanguage: prompt.targetLanguage || defaultLanguage,
        previewSettings:
          userStore.previewSettings[prompt.targetModel] ||
          ensembleStore.modelSettings[prompt.targetModel],
        resultCount: prompt.resultCount || 1,
      };

      let currentTimeout = 0;
      let timeoutStat: NodeJS.Timeout | undefined;

      try {
        const controller = new AbortController();
        const { signal } = controller;

        timeoutStat = setInterval(() => {
          currentTimeout += 1;
          if (currentTimeout > arenaStore.timeout * 10) {
            clearInterval(timeoutStat);
            this.abortRequest(prompt, controller);
          }
        }, 100);

        // get optimized prompt when features include 'preview'
        if (features.includes('preview')) {
          const result = await groupedFunctions.call<PromptResponseType>(
            'autoTune',
            'getPrompt',
            promptRequest
          );
          const data = result.data;
          prompt.id = data.id;
          prompt.promptOptimized = data.promptOptimized;

          await this.generatePreview(prompt, 'after', signal, isStreaming, '');
        } else {
          await this.runTemplate(prompt, signal, isStreaming);
        }


        prompt.timeElapsed = currentTimeout / 10;
        logEvent(analytics, 'PP_run_template', {
          stage: 'success',
          targetModel: prompt.targetModel,
          prompt: prompt.promptOptimized,
        });
      } catch (error) {
        logEvent(analytics, 'PP_run_template', {
          stage: 'error',
          targetModel: prompt.targetModel,
          prompt: prompt.promptOptimized,
        });

        if (error instanceof Error) {
          if (!prompt.error || !error.message.includes('aborted a request')) {
            prompt.error = model?.modality === 'text' ? error.message : '';
            this.handleError(error);
          }
        }
        throw error;
      } finally {
        prompt.isBusy = false;
        userStore.loadCredits();
        clearInterval(timeoutStat);
      }
    },

    async runTemplate(
      prompt: PromptUIType,
      signal?: AbortSignal,
      stream = false,
    ) {
      let currentTimeout = 0;
      const timeoutStat = setInterval(() => {
        currentTimeout += 1;
      }, 100);

      prompt.previewResults = {
        ...prompt.previewResults,
        before: '',
        beforeStream: [],
        after: '',
        afterStream: [],
        afterLoading: true,
        afterStreamStatus: STREAM_STATUS.RUNNING,
      };

      try {
        const response = await groupedFunctions.request('autoTune', 'po_runTemplate', {
          body: JSON.stringify({
            prompt: prompt.prompt,
            targetModel: prompt.targetModel,
            targetLanguage: prompt.targetLanguage,
            previewSettings: prompt.previewSettings,
            previewVariables: prompt.previewVariables,
            stream,
          }),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          signal,
        });


        if (stream) {
          prompt.previewResults.afterStream = prompt.previewResults.afterStream ?? [];
          for await (const event of response as unknown as AsyncIterable<any>) {
            prompt.previewResults.afterStream.push(event.data);
            prompt.previewResults.afterTimeElapsed = currentTimeout / 10;
          }
          prompt.previewResults.afterStreamStatus = STREAM_STATUS.DONE;
          prompt.previewResults.after = prompt.previewResults.afterStream.join('');
        } else {
          const data = (response as any).data;
          prompt.previewResults.after = data;
          prompt.timeElapsed = currentTimeout / 10;
          prompt.previewResults.afterStreamStatus = STREAM_STATUS.DONE;
        }
      } catch (err) {
        prompt.previewResults.after = '';
        prompt.previewResults.afterStreamStatus = STREAM_STATUS.ERROR;
        this.handleError(err);
        throw err;
      } finally {
        if (prompt.previewResults?.afterLoading) {
          prompt.previewResults.afterLoading = false;
        }
        clearInterval(timeoutStat);
      }
    },
  },
});
