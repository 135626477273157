export enum USER_TYPE {
  FIRST_USER = 'first_user',
  SHALLOW_USER = 'shallow_user',
  DEEP_USER = 'deep_user',
  PRO_USER = 'pro_user',
}

export const LEVELS_USER: { [key in USER_TYPE]: number } = {
  [USER_TYPE.FIRST_USER]: 1,
  [USER_TYPE.SHALLOW_USER]: 3,
  [USER_TYPE.DEEP_USER]: 5,
  [USER_TYPE.PRO_USER]: 10,
};

export enum DISCOUNTS {
  NEW_USER = 0.2,
  AFFILIATE_USER = 0.5,
}

export enum USER_ROLES {
  DESIGNER = 'graphic_designer',
  WRITER = 'content_creator',
  MARKETING = 'marketing_specialist',
  SOCIAL_MEDIA = 'social_media_specialist',
  DEVELOPER = 'developer',
  DATA = 'data_analyst',
}

export enum USER_PLANS {
  CREATE_IMAGE = 'create_image_for_posts',
  IMPROVE_WRITING = 'improve_writing',
  TAILOR_CONTENT = 'tailor_content_for_audience',
  DESIGN_MARKETING = 'design_marketing_graphics',
  BUILD_SOCIAL_MEDIA = 'build_social_media_engagement',
  CRAFT_EMAILS = 'craft_emails',
  BUILD_SMART_AI = 'build_smart_ai_features',
}
