
export const supportedModels = [
  {
    name: 'gpt-3.5-turbo',
    icon: 'img:/openai.svg',
    website: 'https://chat.openai.com/',
    language: 'multi',
    modality: 'text',
    logo: 'openai.svg',
    credits: 0,
    stream: true,
  },
  {
    name: 'gpt-4o',
    icon: 'img:/openai.svg',
    website: 'https://chat.openai.com/',
    language: 'multi',
    modality: 'text',
    logo: 'openai.svg',
    credits: 1,
    stream: true,
  },
  {
    name: 'claude-3-sonnet',
    icon: 'img:/claude.svg',
    website: 'https://console.anthropic.com/claude',
    language: 'multi',
    modality: 'text',
    logo: 'claude.svg',
    credits: 0,
    stream: true,
  },
  // {
  //   name: 'claude-3-opus',
  //   icon: 'img:/claude.svg',
  //   website: 'https://console.anthropic.com/claude',
  //   language: 'multi',
  //   modality: 'text',
  //   logo: 'claude.svg',
  //   credits: 1,
  //   stream: true,
  // },
  {
    name: 'llama3-70b-instruct',
    icon: 'img:/meta.svg',
    website: 'https://ai.meta.com/llama/',
    language: 'en',
    modality: 'text',
    logo: 'meta.svg',
    credits: 0,
    stream: true,
  },
  {
    name: 'phi-3',
    icon: 'img:/microsoft.svg',
    website: 'https://huggingface.co/collections/microsoft/phi-3-6626e15e9585a200d2d761e3',
    language: 'multi',
    modality: 'text',
    logo: 'openai.svg',
    credits: 0,
    stream: true,
  },
  {
    name: 'gpt-4',
    icon: 'img:/openai.svg',
    website: 'https://chat.openai.com/',
    language: 'multi',
    modality: 'text',
    logo: 'openai.svg',
    credits: 1,
    stream: true,
  },
  {
    name: 'gpt-4-turbo',
    icon: 'img:/openai.svg',
    website: 'https://chat.openai.com/',
    language: 'multi',
    modality: 'text',
    logo: 'openai.svg',
    credits: 1,
    stream: true,
  },
  {
    name: 'claude-3-haiku',
    icon: 'img:/claude.svg',
    website: 'https://console.anthropic.com/claude',
    language: 'multi',
    modality: 'text',
    logo: 'claude.svg',
    credits: 0,
    stream: true,
  },
  // {
  //   name: 'gpt-4-turbo-vision',
  //   icon: 'img:/openai.svg',
  //   website: 'https://chat.openai.com/',
  //   language: 'multi',
  //   modality: 'text',
  //   logo: 'openai.svg',
  //   credits: 1,
  // },
  {
    name: 'cogenerate',
    icon: 'img:/cohere.svg',
    website: 'https://dashboard.cohere.ai/playground/generate',
    language: 'en',
    modality: 'text',
    logo: 'cohere.svg',
    credits: 0,
    stream: false,
  },
  {
    name: 'ernie-bot-4',
    icon: 'img:/ernie.svg',
    website: 'https://yiyan.baidu.com/',
    language: 'multi',
    modality: 'text',
    logo: 'ernie.svg',
    credits: 1,
    stream: true,
  },
  {
    name: 'chatglm-turbo',
    icon: 'img:/zhipu.png',
    website: 'https://chatglm.cn/',
    language: 'multi',
    modality: 'text',
    logo: 'zhipu.png',
    credits: 0,
    stream: true,
  },
  {
    name: 'llama-2-70b-chat',
    icon: 'img:/meta.svg',
    website: 'https://ai.meta.com/llama/',
    language: 'en',
    modality: 'text',
    logo: 'meta.svg',
    credits: 1,
    stream: true,
  },
  {
    name: 'mistral-7b-instruct',
    icon: 'img:/mistral.png',
    website: 'https://mistral.ai/news/announcing-mistral-7b/',
    language: 'en',
    modality: 'text',
    logo: 'mistral.png',
    credits: 0,
    stream: true,
  },
  {
    name: 'dalle-3',
    icon: 'img:/dalle.svg',
    website: 'https://labs.openai.com/',
    language: 'multi',
    modality: 'image',
    logo: 'dalle.svg',
    credits: 0,
    stream: false,
  },
  {
    name: 'sdxl',
    icon: 'img:/stability.png',
    website: 'https://stability.ai/stable-diffusion',
    language: 'en',
    modality: 'image',
    logo: 'stability.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'midjourney',
    icon: 'img:/mj.png',
    website: 'https://www.midjourney.com/',
    language: 'en',
    modality: 'image',
    logo: 'mj.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'sd',
    icon: 'img:/sd.png',
    website: 'https://beta.dreamstudio.ai/',
    language: 'en',
    modality: 'image',
    logo: 'sd.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'kandinsky',
    icon: 'img:/kandinsky.svg',
    website: 'https://fusionbrain.ai/diffusion',
    language: 'en',
    modality: 'image',
    logo: 'kandinsky.svg',
    credits: 0,
    stream: false,
  },
  {
    name: 'dreamshaper',
    icon: 'img:/civitai.png',
    website: 'https://civitai.com/models/4384/dreamshaper',
    language: 'en',
    modality: 'image',
    logo: 'civitai.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'absolutereality',
    icon: 'img:/civitai.png',
    website: 'https://civitai.com/models/81458/absolutereality',
    language: 'en',
    modality: 'image',
    logo: 'civitai.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'anything',
    icon: 'img:/civitai.png',
    website: 'https://civitai.com/models/66/anything-v3',
    language: 'en',
    modality: 'image',
    logo: 'civitai.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'deliberate',
    icon: 'img:/civitai.png',
    website: 'https://civitai.com/models/4823/deliberate',
    language: 'en',
    modality: 'image',
    logo: 'civitai.png',
    credits: 0,
    stream: false,
  },
  {
    name: 'sdxl-emoji',
    icon: 'img:/replicate.svg',
    website: 'https://replicate.com/fofr/sdxl-emoji',
    language: 'en',
    modality: 'image',
    logo: 'replicate.svg',
    noOptimize: true,
    credits: 0,
    stream: false,
  },
  {
    name: 'dalle',
    icon: 'img:/dalle.svg',
    website: 'https://labs.openai.com/',
    language: 'multi',
    modality: 'image',
    logo: 'dalle.svg',
    credits: 0,
    stream: false,
  },
] as const;

export const defaultModelSettings = {
  jinachat: {
    temperature: 0.5,
    max_tokens: 1000,
    top_p: 1,
    frequency_penalty: 0.5,
    presence_penalty: 0,
    stream: true,
  },
  chatgpt: {
    temperature: 0.5,
    max_tokens: 1000,
    top_p: 1,
    frequency_penalty: 0.5,
    presence_penalty: 0,
    stream: true,
  },
  'gpt-4-turbo': {
    temperature: 0.5,
    max_tokens: 1000,
    top_p: 1,
    stream: true,
  },
  'gpt-4-turbo-vision': {
    temperature: 0.5,
    max_tokens: 1000,
    top_p: 1,
    stream: true,
  },
  'gpt-4': {
    temperature: 0.5,
    max_tokens: 1000,
    top_p: 1,
    frequency_penalty: 0.5,
    presence_penalty: 0,
    stream: true,
  },
  claude: {
    temperature: 0.5,
    max_tokens_to_sample: 1000,
    top_p: -1,
    stream: true,
  },
  'claude-2': {
    temperature: 0.5,
    max_tokens_to_sample: 1000,
    top_p: -1,
    stream: true,
  },
  'ernie-bot-turbo': {
    temperature: 0.95,
    top_p: 0.8,
  },
  'ernie-bot-4': {
    temperature: 0.95,
    top_p: 0.8,
  },
  'chatglm-turbo': {
    temperature: 0.95,
    top_p: 0.8,
  },
  'llama-2-70b-chat': {
    temperature: 0.5,
    top_p: 1,
  },
  'llama-2-13b-chat': {
    temperature: 0.5,
    top_p: 1,
  },
  'llama-2-7b-chat': {
    temperature: 0.5,
    top_p: 1,
  },
  'mistral-7b-instruct': {
    temperature: 0.5,
    top_p: 1,
  },
  cogenerate: {
    max_tokens: 1000,
    temperature: 0.5,
    k: 0,
    p: 0.75,
  },
  dalle: {
    size: '512x512',
  },
  'dalle-3': {
    size: '1024x1024',
    style: 'vivid',
  },
  sd: {
    width: 512,
    height: 512,
    cfgScale: 13,
    steps: 25,
  },
  sdxl: {
    width: 1024,
    height: 1024,
    num_inference_steps: 50,
  },
  'sdxl-emoji': {
    width: 1024,
    height: 1024,
    num_inference_steps: 50,
  },
  midjourney: {
    width: 512,
    height: 512,
    guidance_scale: 8,
  },
  lexica: {
    image_quality: 'md2',
  },
  kandinsky: {
    width: 512,
    height: 512,
    num_inference_steps: 75,
    num_inference_steps_prior: 25,
  },
  'stablelm-tuned-alpha-7b': {
    max_tokens: 1000,
    temperature: 0.5,
    top_p: 1,
    repetition_penalty: 1.2,
  },
  dreamshaper: {
    width: 512,
    height: 512,
    num_inference_steps: 50,
  },
  absolutereality: {
    width: 512,
    height: 512,
    num_inference_steps: 50,
  },
  anything: {
    width: 512,
    height: 512,
    num_inference_steps: 50,
  },
  deliberate: {
    width: 512,
    height: 512,
    num_inference_steps: 50,
  },
};

export enum SortByOptions {
  POPULARITY = 'usedCount',
  TIME = 'createdAt',
}

export enum FilterOptions {
  MINE_ONLY,
  COMMUNITY_ONLY,
}
export const predefinedVariables = [
  'ISODATE',
  'DATE',
  'TIME',
  'CITY',
  'COUNTRY',
  'REGION',
  'TIMEZONE',
  'LOCATION',
  'LOCAL_DATE',
  'LOCAL_TIME',
  'LOCAL_DATETIME',
];

export enum MODALITY_TABS {
  TEXT = 'text',
  IMAGE = 'image',
  ENSEMBLE = 'ensemble',
}

export const supportChannels = [
  {
    icon: 'email',
    label: 'billing_issue',
    link: 'mailto:billing@jina.ai',
  },
  {
    icon: 'fa-brands fa-discord',
    label: 'join_discord_v3',
    link: 'https://discord.gg/VCnHuGtGks',
  },
  {
    icon: 'fa-brands fa-linkedin',
    label: 'follow_us_on_linkedin',
    link: 'https://www.linkedin.com/company/jinaai/',
  },
  {
    icon: 'fa-brands fa-x-twitter',
    label: 'follow_us_on_twitter',
    link: 'https://twitter.com/jinaAI_/',
  },
  {
    icon: 'fa-brands fa-youtube',
    label: 'follow_us_on_youtube',
    link: 'https://www.youtube.com/c/JinaAI',
  },
  {
    icon: 'home',
    label: 'visit_our_website',
    link: 'https://jina.ai/',
  },
  // {
  //   icon: 'fa-solid fa-map-location-dot',
  //   label: 'Jina AI GmbH. Leipziger Str. 96, 10117 Berlin, Germany',
  //   link: 'https://goo.gl/maps/NpEmbf2G5CM8U46d9',
  // },
];

export const REG_URL =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
// RegExp that detects variables in PAAS
export const REG_PAAS_VARIABLES = (() => {
  try {
    return new RegExp(
      '(?:(?<!\\$)\\$\\{|\\{|\\[)[\\w\\p{L}]+(?=\b|\\}|\\])',
      'gu'
    );
  } catch (e) {
    // Some old safari can't support advanced syntax
    // This regexp could give worse results
    return new RegExp('(?:\\$\\{|\\{|\\[)[\\w\\p{L}]+(?=\\s|\\}|\\])', 'gu');
  }
})();

export enum TOUR_FEATURES {
  TEXT = 'text',
  IMAGE = 'image',
  ADDON = 'addon',
  VARIABLES = 'variables',
  WELCOME = 'welcome',
  CANCEL = 'cancel',
  NEW_USER_WELCOME = 'new_user_welcome',
  INTERACTIVE = 'interactive',
};

export const TOUR_OPTIMIZE_FEATURES: Record<string, string> = {
  [TOUR_FEATURES.TEXT]: 'auto-tune-prompt-basics-text',
  [TOUR_FEATURES.IMAGE]: 'auto-tune-prompt-basics-image',
  [TOUR_FEATURES.ADDON]: 'auto-tune-add-ons',
  [TOUR_FEATURES.VARIABLES]: 'auto-tune-variables'
};

export const TOUR_ACHIEVEMENTS: Record<string, string> = {
  [TOUR_FEATURES.TEXT]: 'finish-prompt-tutorial',
  [TOUR_FEATURES.IMAGE]: 'finish-prompt-tutorial-image',
  [TOUR_FEATURES.ADDON]: 'finish-prompt-tutorial-add-ons',
  [TOUR_FEATURES.VARIABLES]: 'finish-prompt-tutorial-variables',
};

export const TUTORIAL_VIDEOS = [
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/05/promptperfect-first-optimize.mp4',
    title: 'how_to_use_optimize_mode',
    description: 'optimize_prompt_description',
    image: 'optimize-prompt.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/05/promptperfect-streamline-mode.mp4',
    title: 'how_to_use_streamline_mode',
    description: 'streamline_description',
    image: 'streamline.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/05/promptperfect-template.mp4',
    title: 'how_to_use_templates',
    description: 'templates_description_v3',
    image: 'make-template.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/05/promptperfect-reverse-engineer.mp4',
    title: 'how_to_reverse_engineer_prompt_from_image',
    description: 'upload_image_get_prompt',
    image: 'reverse-image.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/05/promptperfect-arena.mp4',
    title: 'how_to_compare_multiple_models',
    description: 'arena_description_v1',
    image: 'arena-mode.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/05/promptperfect-upload-csv.mp4',
    title: 'how_to_upload_prompt_as_csv',
    description: 'upload_csv_tooltip',
    image: 'csv-upload.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/06/promptperfect-fewshot.mp4',
    title: 'how_to_build_fewshot_prompt',
    description: 'home_fewshot_description',
    image: 'fewshot.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/06/promptperfect-paas-1.mp4',
    title: 'how_to_deploy_prompt_as_service',
    description: 'deploy_prompt_as_service_description',
    image: 'paas.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/07/promptperfect-functions.mp4',
    title: 'how_to_use_functions',
    description: 'functions_description',
    image: 'functions.svg',
  },
  {
    url: 'https://jina-ai-gmbh.ghost.io/content/media/2023/09/promptperfect-database2.mp4',
    title: 'how_to_use_database',
    description: 'database_description',
    image: 'database.svg',
  },
];

export const MENUS = ['features','user_cases','pricing','blogs','faq'];
