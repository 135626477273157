type FeatureTogglesProps = Record<string, Record<string, boolean>>;

const featureToggles: FeatureTogglesProps = {
  production: {
    agentsPage: true,
    interactiveOptimizer: true
  },
  development: {
    agentsPage: true,
    interactiveOptimizer: true,
  },
};

const featureToggle = featureToggles[process.env.NODE_ENV];

export default featureToggle;
