import { defineStore } from 'pinia';
import { Dialog, LocalStorage, Notify } from 'quasar';
import { User } from 'firebase/auth';
import { ref } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { auth, groupedFunctions } from 'src/fb';
import { logEvent, setUserId } from 'firebase/analytics';
import routes, { featuresRoutes } from 'src/router/routes';
import { APIHubble } from 'src/shared/api/hubble';
import { useAchievementStore } from './achievement';
import { defaultModelSettings } from 'src/constants';
import { SubscriptionLevel, useLegacySubscriptionStore } from './legacy-subscription';
import { useSubscriptionStore } from './subscription';
import RateLimitPlanExpiredDialog from 'components/rate-limit/RateLimitPlanExpiredDialog.vue';
import type Stripe from 'stripe';
import { defaultLanguage } from '../i18n';
import { analytics } from 'src/fb';
import Hotjar from '@hotjar/browser';
import { LEVELS_USER, USER_TYPE, DISCOUNTS } from 'src/constants/user';

export interface TrialItem {
  // Unit is second
  trialEnd: number;
  // Unit is second
  trialStart: number;
}

export interface CreditInfoType {
  total: number;
  remaining: number;
  stripeInvoiceHistory?: Stripe.Invoice[];
  stripeSubscriptions?: Stripe.Subscription[];
  trialHistory?: Record<string, TrialItem>;
  memberLevel?: number;
  memberLevelExpireAt?: Date;
  creditSource?: 'coinCounter' | 'local' | string;
  connectedProducts?: string[];
};
interface CampaignOffer {
  appliesTo?: string[];
  validDays: number;
  maxRedemption: number;
  percentOff?: number;
  amountOff?: number;
  currency?: string;
}
export interface State {
  user: User | null;
  loadingCredits: boolean;
  initiatedCredits: boolean;
  credit: CreditInfoType;
  loginDialogOpened: boolean;
  booster: number;
  checkInDates: string[];
  todayBonus: number;
  loadingCoupon: boolean;
  coupon: {
    code?: string;
    expiresAt?: number;
    redeemed?: boolean;
  };
  previewSettings: {
    [key: string]: { [key: string]: number | string | boolean; };
  };
  highlightTourIcon: boolean | undefined;
  isRateLimitSubscriber: boolean;
  isPremiumPlanBannerVisible: boolean;
  optimizerOnLeft: boolean;
  campaigns: {
    name: string; stripePromoCode?: string, offer?: CampaignOffer;
    type: 'public' | 'specialOffer',
    active: true;
    enrolled: boolean;
  }[];
  specialOffers: {
    campaign: string;
    uid: string;
    stripePromoCode?: string;
    offer?: CampaignOffer;
    redeemed: number;
    createdAt: string;
    expireAt: string;
  }[];
  _campaignLock: boolean;

  settings: UserSettingsType;
  statistics: UserStatisticsType;
  lastRefreshedAt: number;
  debug: boolean;
  hasAffiliateDiscount: boolean;
  showWelcomeDialog: boolean;
  initiated: boolean;
}

interface UserSettingsType {
  language: string;
  darkMode: boolean | 'auto';
  createFromFloatingButton: boolean;
  sideDrawerFeatures: RouteRecordRaw[];
  /**
   * Whether to show AutoTune tutorial
   */
  showAutoTune: boolean;
  /**
   * Whether to show the Interactive tutorial
   */
  showInteractive: boolean;
  dismissedPremiumPlanBanner: boolean;
  hasShownAssistantTour: boolean;
  hasShownOptimizerTour: boolean;
  role: string;
  plan: string;
}

interface UserStatisticsType {
  numUsages: number;
  userType?: USER_TYPE;
}

export const useUserStore = defineStore('user', {
  persist: {
    paths: [
      'user',
      'coupon',
      'highlightTourIcon',
      'previewSettings',
      'isRateLimitSubscriber',
      'optimizerOnLeft',
      'isPremiumPlanBannerVisible',
      'lastRefreshedAt',
      'settings',
      'credit'
    ],
  },

  state: (): State => {

    const state: State = {
      user: null,
      loadingCredits: false,
      initiatedCredits: false,
      credit: { total: 0, remaining: 0, stripeInvoiceHistory: [], memberLevel: 0 },
      loginDialogOpened: false,
      booster: 1,
      checkInDates: [],
      todayBonus: 0,
      loadingCoupon: false,
      coupon: {},
      previewSettings: { ...defaultModelSettings },
      highlightTourIcon: true,
      optimizerOnLeft: false,
      isRateLimitSubscriber: false,
      isPremiumPlanBannerVisible: false,
      campaigns: [],
      specialOffers: [],
      _campaignLock: false,
      settings: {
        darkMode: 'auto',
        language: defaultLanguage,
        createFromFloatingButton: false,
        sideDrawerFeatures: featuresRoutes.value,
        showAutoTune: true,
        showInteractive: true,
        dismissedPremiumPlanBanner: false,
        hasShownAssistantTour: false,
        hasShownOptimizerTour: false,
        role: '',
        plan: '',
      },
      statistics: {
        numUsages: 0,
      },
      lastRefreshedAt: 0,
      debug: false,
      hasAffiliateDiscount: !!localStorage.getItem('affiliateId'),
      showWelcomeDialog: false,
      initiated: false,
    };

    return state;
  },

  getters: {
    language: (state) => {
      return state.settings.language;
    },
    hasValidCoupon: (state) => {
      return (
        state.coupon.code &&
        state.coupon.expiresAt &&
        new Date(state.coupon.expiresAt) > new Date() &&
        state.coupon.redeemed === false
      );
    },
    isNewUser: (state) => {
      return (
        state.user?.metadata.creationTime &&
        Date.now() - new Date(state.user.metadata.creationTime).getTime() <
        24 * 60 * 60 * 1000
      );
    },
    isRateLimit: (state) => {
      const memberLevel = state.credit.memberLevel ?? 0;
      return (memberLevel >=10 && memberLevel <= 20) || (memberLevel === 0 && state.credit.remaining === 0) || memberLevel >= 99;
    },
    planDiscount: (state) => {
      return state.hasAffiliateDiscount ? DISCOUNTS.AFFILIATE_USER : DISCOUNTS.NEW_USER;
    },
    needsOnBoarding: (state) => {
      if (state.user?.metadata?.creationTime && new Date(state.user.metadata.creationTime).getTime() > new Date('2024-06-06T11:00:00Z').getTime()) {
        return !state.settings.role || !state.settings.plan;
      }
    },
  },

  actions: {
    async init() {
      if (this.initiated) return;
      if (this.user) {
        try {
          // Get data from DB
          const {settings, statistics} = await this.getPreferences();
          // check if has value, if not, restore from localStorage
          const localPreferences = LocalStorage.getItem(this.user.uid) as UserSettingsType & UserStatisticsType;

          const localTour = JSON.parse(LocalStorage.getItem('tour') ?? '{}') as { hasShownAssistantTour: boolean, hasShownOptimizerTour: boolean };

         // initiate settings
          const {
            darkMode,
            language,
            createFromFloatingButton,
            sideDrawerFeatures,
            showAutoTune,
            showInteractive,
            dismissedPremiumPlanBanner,
            hasShownAssistantTour,
            hasShownOptimizerTour,
            role,
            plan,
          } = Object.assign({}, localPreferences, localTour, settings);

          this.settings = {
            darkMode: darkMode ?? this.settings.darkMode,
            language: language ?? this.settings.language,
            createFromFloatingButton: createFromFloatingButton ?? this.settings.createFromFloatingButton,
            sideDrawerFeatures: sideDrawerFeatures ?? this.settings.sideDrawerFeatures,
            showAutoTune: showAutoTune ?? this.settings.showAutoTune,
            showInteractive: showInteractive ?? this.settings.showInteractive,
            dismissedPremiumPlanBanner: dismissedPremiumPlanBanner ?? this.settings.dismissedPremiumPlanBanner,
            hasShownAssistantTour: hasShownAssistantTour ?? this.settings.hasShownAssistantTour,
            hasShownOptimizerTour: hasShownOptimizerTour ?? this.settings.hasShownOptimizerTour,
            role: role ?? this.settings.role,
            plan: plan ?? this.settings.plan,
          }

          // update icons for side drawer
          this.settings.sideDrawerFeatures = this.settings.sideDrawerFeatures.map((route) => {
            const matchedRoute = routes[1].children!.find((x) => x.name === route.name);
            if (matchedRoute) {
              route.meta!.icon = matchedRoute.meta?.icon;
            }
            return route;
          });

          // initiate statistics
          const {numUsages, userType} = Object.assign({}, localPreferences, statistics);

          this.statistics = {
            numUsages: numUsages ?? this.statistics.numUsages,
            userType: userType ?? this.statistics.userType,
          }

          this.initiated = true;
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    setUser(userInfo: User) {
      this.user = userInfo;

      if (userInfo.uid) {
        setUserId(analytics, userInfo.uid);
      }

      if (userInfo) {
        Hotjar.identify(userInfo.uid, {
          email: `***@${userInfo.email?.split('@')[1]}`,
        });
      }
    },
    showAuthDialog() {
      if (this.user) {
        return;
      }
      this.loginDialogOpened = true;
    },

    async deleteAccount() {
      await groupedFunctions.call('account', 'deleteAccount');
      await this.logout();
    },

    async logout() {
      logEvent(analytics, 'logout');

      this.user = null;
      await auth.signOut();

      if (this.router.currentRoute.value.meta.requiresAuth) {
        this.router.push({ name: 'landing' });
      }
    },

    async loadCredits() {
      if (!this.user) {
        return;
      }
      this.loadingCredits = true;

      const achievementStore = useAchievementStore();
      const legacySubscriptionStore = useLegacySubscriptionStore();
      const subscriptionStore = useSubscriptionStore();

      const { data } = await groupedFunctions.call<
        CreditInfoType & { achievements?: string[]; campaigns: any[], specialOffers: []; }
      >('account', 'getCreditsAccount');
      if (data.memberLevelExpireAt) {
        data.memberLevelExpireAt = new Date(data.memberLevelExpireAt);
      }

      let booster = 0;
      if (data) {
        this.credit = data;
        this.credit.remaining = this.credit.remaining ?? 0;
        this.campaigns = data.campaigns || [];
        this.specialOffers = data.specialOffers || [];

        data.achievements?.forEach((achievementId) => {
          achievementStore.achieve(achievementId);
        });

        if (data.memberLevel) {
          if (data.memberLevel >= 99) {
            booster = 5;
          } else if (data.memberLevel >= 3) {
            booster = 5;
          } else if (data.memberLevel >= 2) {
            booster = 3;
          } else if (data.memberLevel >= 1) {
            booster = 2;
          }
        }

        if (this.credit.stripeInvoiceHistory) {
          legacySubscriptionStore.setSubscription(this.credit.stripeInvoiceHistory);
        }

        if (this.credit.stripeSubscriptions) {
          subscriptionStore.setSubscription({
            stripeSubscriptions: this.credit.stripeSubscriptions,
            trialHistory: this.credit.trialHistory,
          });
        }

        if (
          this.isRateLimitSubscriber &&
          !subscriptionStore.isSubscriber &&
          this.credit.remaining > 0
        ) {
          Dialog.create({
            component: RateLimitPlanExpiredDialog,
          });
        }

        this.isRateLimitSubscriber = subscriptionStore.isSubscriber;

        switch (legacySubscriptionStore.topSubscription) {
          case SubscriptionLevel.Standard:
            booster = 1;
          case SubscriptionLevel.Plus:
            booster = 2;
            break;
          case SubscriptionLevel.Pro:
            booster = 3;
            break;
          case SubscriptionLevel.Promax:
          case SubscriptionLevel.Ultra:
            booster = 5;
            break;
          default:
            booster = 1;
        }

        this.booster = booster === 0 ? 1 : booster;
      }

      if (this.credit.creditSource === 'coinCounter') {
        const client = new APIHubble();
        const toToken = await this.user.getIdToken();
        const {
          parsed: { data },
        } = await client.getAccountDetail(toToken);

        this.credit.connectedProducts = data.customer.map((item) => {
          return item.app;
        });
      }
      this.initiatedCredits = true;
      this.loadingCredits = false;

      await this.checkCoupon();
    },

    async dailyCheckIn() {
      try {
        const { data } = await groupedFunctions.call<{
          dates: string[];
          bonusCredits: number;
        }>('misc', 'dailyCheckIn', {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        this.checkInDates = data.dates.map((d) => d.replaceAll('-', '/'));
        this.todayBonus = data.bonusCredits;
      } catch (e) {
        console.error(e);
      }
    },

    async participateInCampaigns() {
      if (this._campaignLock) {
        return;
      }
      const someEligibleCampaign = this.campaigns.find((c: any) => c.active && !c.enrolled);
      if (!someEligibleCampaign) {
        return;
      }

      if (someEligibleCampaign.type === 'public') {
        this.coupon = {
          code: someEligibleCampaign.stripePromoCode,
          expiresAt: Date.now() + 1000 * 3600 * 24 * (someEligibleCampaign.offer?.validDays || 1),
          redeemed: false
        };
        return;
      }

      this._campaignLock = true;
      const { data } = await groupedFunctions.call<{
        campaign: string;
        uid: string;
        stripePromoCode?: string;
        redeemed?: number;
        createdAt?: string;
        expireAt?: string;
        offer?: CampaignOffer;
      }>('account', 'secureSpecialOffer', {
        campaign: someEligibleCampaign.name,
      }).finally(()=> {
        this._campaignLock = false;
      });

      if (data && data.expireAt && data.stripePromoCode) {
        this.coupon = {
          code: data.stripePromoCode,
          expiresAt: new Date(data.expireAt).valueOf(),
          redeemed: Boolean(data.redeemed)
        };
      }
    },

    async checkCoupon() {
      this.hasAffiliateDiscount = !!localStorage.getItem('affiliateId');
      const nowDate = new Date();
      for (const spo of this.specialOffers) {
        if (spo.expireAt && new Date(spo.expireAt) > nowDate) {
          this.coupon = {
            code: spo.stripePromoCode,
            expiresAt: new Date(spo.expireAt).valueOf(),
            redeemed: Boolean(spo.redeemed)
          };
          return;
        }
      }
      this.coupon = {};
      await this.participateInCampaigns();
    },

    submitUserConversion() {
      this.statistics.numUsages++;
      const { userType, numUsages } = this.statistics;

      if (
        userType !== USER_TYPE.FIRST_USER &&
        numUsages === LEVELS_USER[USER_TYPE.FIRST_USER]
      ) {
        this.statistics.userType = USER_TYPE.FIRST_USER;
        logEvent(analytics, 'PP_convert_first_user');
      }
      if (
        userType !== USER_TYPE.SHALLOW_USER &&
        numUsages === LEVELS_USER[USER_TYPE.SHALLOW_USER]
      ) {
        this.statistics.userType = USER_TYPE.SHALLOW_USER;
        logEvent(analytics, 'PP_convert_shallow_user');
      }
      if (
        userType !== USER_TYPE.DEEP_USER &&
        numUsages === LEVELS_USER[USER_TYPE.DEEP_USER]
      ) {
        this.statistics.userType = USER_TYPE.DEEP_USER;
        logEvent(analytics, 'PP_convert_deep_user');
      }
      if (
        userType !== USER_TYPE.PRO_USER &&
        numUsages === LEVELS_USER[USER_TYPE.PRO_USER]
      ) {
        this.statistics.userType = USER_TYPE.PRO_USER;
        logEvent(analytics, 'PP_convert_pro_user');
      }

      this.savePreferences();
    },

    async savePreferences() {
      // do not send request to server if user is not logged in
      if (!this.user) return;
      const options = {
        settings: this.settings,
        statistics: this.statistics,
      }

      try {
        await groupedFunctions.call('account', 'saveMyPreferences', {
          preferences: options
        });
      } catch (error) {
        this.handleError(error);
      }
    },

    async getPreferences() {
      const settings = await groupedFunctions.call('account', 'getMyPreferences');

      return settings.data as {
        settings: UserSettingsType;
        statistics: UserStatisticsType;
      };
    },

    updateSidebar() {
      // retire old feature that is not in the new version
      this.settings.sideDrawerFeatures =
        this.settings.sideDrawerFeatures.filter((route) => {
          return featuresRoutes.value.find((x) => x.meta?.title === route.meta?.title);
        });

      // add new feature introduced by the new version
      featuresRoutes.value.forEach((route) => {
        if (
          !this.settings.sideDrawerFeatures.find(
            (x) => x.meta?.title === route.meta?.title
          )
        ) {
          // a new feature that is not in the localstorage's sidebar
          this.settings.sideDrawerFeatures.push(route);
        }
      });

      this.savePreferences();
    },

    setLanguage(lang: string) {
      this.settings.language = lang;
      this.savePreferences();
    },

    setTheme(theme: boolean | 'auto') {
      this.settings.darkMode = theme;
      this.savePreferences();
    },

    setCreateFromFloatingButton(value: boolean) {
      this.settings.createFromFloatingButton = value;
      this.savePreferences();
    },

    async showPaymentSuccessFail() {
      const query = this.router.currentRoute.value.query;
      const sessionId = query?.session_id as string;

      if (query.success === undefined) {
        return;
      }

      if (!sessionId || query.success === 'false') {
        logEvent(analytics, 'cancel_purchase');
        return this.router.replace({ query: { ...query, success: undefined, session_id: undefined } });
      }

      try {
        const { data } = await groupedFunctions
          .call<{
            currency: string | null;
            payment_status: Stripe.Checkout.Session.PaymentStatus;
            price: number | null;
            purchased_prices: {
              name: string;
              amount_total: number;
              unit_amount: number;
              quantity: number;
            }[];
          }>('account', 'getStripeCheckoutSessionStatus', { sessionId });

        if (data.payment_status === 'paid') {
          Notify.create({
            message: this.t('payment_success'),
            caption: this.t('payment_success_caption'),
            color: 'positive',
            icon: 'check_circle',
            position: 'top',
          });

          const totalValue = data.purchased_prices.reduce(
            (acc, item) => acc + (item.unit_amount ?? 0) * (item.quantity ?? 0), 0
          );

          await this.loadCredits();

          logEvent(analytics, 'purchase', {
            transaction_id: sessionId,
            value: totalValue / 100,
            currency: data.currency ?? 'USD',
            items: data.purchased_prices?.map((item) => ({
              item_id: item.name,
              item_name: item.name,
              price: item.unit_amount / 100,
              quantity: item.quantity
            })),
          });
        }
      } catch(e) {
        if (e instanceof Error) {
          Notify.create({
            message: e.message,
            caption: this.t('payment_fail'),
            color: 'negative',
            icon: 'error',
          });
        } else {
          Notify.create({
            message: this.t('payment_fail'),
            caption: this.t('payment_fail_caption'),
            color: 'negative',
            icon: 'error',
          });
        }
      } finally {
        return this.router.replace({ query: { ...query, success: undefined, session_id: undefined } });
      }
    }
  },
});

export const isBusyPrompts = ref(false);
export const isBusyStreamline = ref(false);
export const isBusyBilling = ref(false);
export const isBusyFunctions = ref(false);
export const isBusyDatabase = ref(false);
export const isBusyAgents = ref(false);
